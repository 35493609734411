import {
  ActionReducerMapBuilder,
  PayloadAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  SortableListInfo,
  SortableListType,
} from 'components/sortables/Sortable';

import { IDialogueState, StateMan } from 'features/dialogue/dialogueSlice';
import { postFile } from 'features/files/fileAPI';
import { FolderLocation, getFolderName } from 'features/files/fileSlice';
import {
  IMessageCreateReply,
  deleteMessage,
  postMessage,
} from 'features/message/messageAPI';
import { IUser } from 'features/user/userAPI';
import {
  DeepFindObjectType,
  deepFindItem,
  reFindObject,
} from 'helpers/finders';
import { AnyOrderObject, pickProps, sameId } from 'helpers/objects';
import { IList } from './listAPI';
import {
  IListItem,
  deleteListItem,
  patchListItem,
  postListItem,
} from './listItemAPI';

export const postListItemAsync = createAsyncThunk(
  'listItem/postListItem',
  async (
    {
      data,
      listId,
      order,
      alt,
      author,
      file,
      pinned,
      stateMan,
    }: {
      data: IMessageCreateReply;
      listId: number;
      order: string;
      alt?: string | null;
      author: IUser;
      file?: File & { data?: any };
      pinned?: boolean;
      stateMan: StateMan | null;
    },
    thunkAPI,
  ) => {
    try {
      let res;
      if (data.files != null && data.files.length !== 0) {
        const file: any = data.files[0];
        data.filesAdd = [{ id: file.id }];
        res = await postMessage(data);
        res.data.files = [{ ...file }];
      } else if (file) {
        const fd = await postFile(file, {
          description: 'User upload',
          folder: getFolderName(FolderLocation.Dialogue, 'UserUploads'),
          order: '',
          size: file.size,
          mimeType: file.type,
        });
        data.filesAdd = [{ id: fd.data._id }];
        file.data = [
          {
            description: 'User upload',
            id: fd.data._id,
            fileName: file.name,
            mimeType: file.type,
            order: '',
            size: file.size,
            uri: fd.data.message,
          },
        ];
        res = await postMessage(data);
        // res.data.files = [{ id: fd.data._id, uri: fd.data.message }];
      } else res = await postMessage(data);
      const message = { ...data, id: res.data._id, files: res.data.files };
      let listItem = {
        list: listId,
        coordinate_x: 0,
        coordinate_y: 0,
        message: res.data._id,
        order: order,
        alt: alt,
        pinned: pinned,
      };
      return {
        response: await postListItem(listItem),
        stateMan,
        listItem,
        message,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const patchListItemAsync = createAsyncThunk(
  'listItem/updateListItem',
  async (
    {
      data,
      stateMan,
    }: {
      data: IListItem;
      stateMan: StateMan;
    },
    thunkAPI,
  ) => {
    try {
      return { response: await patchListItem(data), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const deleteListItemAsync = createAsyncThunk(
  'listItem/deleteListItem',
  async (
    {
      id,
      stateMan,
      messageId,
    }: {
      id: number;
      stateMan: StateMan;
      messageId: number;
    },
    thunkAPI,
  ) => {
    try {
      // let deleteMessageResponse = await deleteMessage(id);
      return {
        // TODO: backend call for deleting list item and message at once
        // TODO: same for lists, blocks, phases, dialogues, projects, etc.
        response: await deleteListItem(id).then((response) => {
          if (response.status === 204) {
            deleteMessage(messageId);
          }
        }),
        stateMan,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

type MoveListItemProps = {
  sourceItem: AnyOrderObject;
  sourceList: SortableListInfo;
  destinationItem?: AnyOrderObject;
  destinationList?: SortableListInfo;
  data: IListItem;
  stateMan: StateMan;
};
type MoveCanvasItemProps = {
  from: DeepFindObjectType;
  data: IListItem;
  stateMan: StateMan;
};

export function addListItemReducers() {
  return {
    moveListItem: (state: any, action: PayloadAction<MoveListItemProps>) => {
      const {
        sourceItem,
        sourceList,
        destinationItem,
        destinationList,
        data,
        stateMan,
      } = { ...action.payload };
      const stateObj = stateMan(state);
      const fromObj = deepFindItem(
        sourceItem.id,
        sourceList.id,
        stateObj,
        sourceList.listType,
      );
      const toObj = deepFindItem(
        destinationItem?.id ?? -1,
        destinationList?.id ?? -1,
        stateObj,
        destinationList?.listType ?? SortableListType.None,
      );
      if (!fromObj || !fromObj.object) return;
      if (!toObj || !toObj.list) return;
      const fromItems = (fromObj.list as IList).listItems;
      const toItems = (toObj.list as IList).listItems;
      if (!fromItems || !toItems) return;
      if (!sameId(fromObj.list, toObj.list)) {
        // we're moving between lists, so they need updating
        // get the index from the unsorted array
        const unsortedFromIndex = fromItems.findIndex(
          (i) => i.id === fromObj.object!.id,
        );
        // update the lists
        fromItems.splice(unsortedFromIndex, 1);
        // add the item to the new list
        toItems.push(data);
        // update the list attribute
        data.list = toObj.list;
      }
      const item = fromObj?.object;
      if (item)
        Object.assign(item, {
          ...item,
          // update only the order and list, the rest may be outdated
          ...pickProps(data, ['id', 'order', 'list']),
        });
    },
    moveCanvasItem: (
      state: any,
      action: PayloadAction<MoveCanvasItemProps>,
    ) => {
      const data = { ...action.payload.data };
      const stateObj = action.payload.stateMan(state);
      const fromObj = reFindObject(action.payload.from, stateObj);
      if (!fromObj || !fromObj.object) return;
      const item = fromObj?.object;
      if (item) Object.assign(item, data);
    },
  };
}

export function addListItemCases(
  builder: ActionReducerMapBuilder<IDialogueState>,
) {
  builder
    .addCase(patchListItemAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(patchListItemAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const id = action.meta.arg.data.id!;
      const data = { ...action.meta.arg.data };
      const item = stateObj.hasOwnProperty('listItems')
        ? stateObj.listItems.find((i: IListItem) => i.id === id)
        : deepFindItem(
            id,
            action.meta.arg.data.list?.id ?? -1,
            stateObj,
            SortableListType.Items,
          );
      const newData = { ...item, ...data };
      if (item) Object.assign(item, newData);
    })
    .addCase(patchListItemAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(postListItemAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(postListItemAsync.fulfilled, (state, action) => {
      if (!action.meta.arg.stateMan) return; // no stateMan, no state update
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const id = action.payload.response.data._id;
      const list = stateObj.hasOwnProperty('listItems')
        ? stateObj
        : stateObj.hasOwnProperty('list')
          ? stateObj.list
          : deepFindItem(
              id,
              action.payload.listItem.list,
              stateObj,
              SortableListType.Items,
            )?.list;
      if (list && list.listItems) {
        list.listItems.push({
          ...action.payload.listItem,
          id: id,
          // message: action.meta.arg.data,
          message: {
            ...action.payload.message,
            author: action.meta.arg.author,
            files: action.meta.arg.file?.data ?? action.payload.message.files,
            // files: action.payload.message.files,
          },
        });
      } else {
        console.log(
          'Oh nooo, your new list item got lost! But no worries: we saved it already. Just refresh the page.',
        );
      }
    })
    .addCase(postListItemAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(deleteListItemAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(deleteListItemAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const id = action.meta.arg.id;
      stateObj.splice(
        stateObj.findIndex((m: any) => m.id === id),
        1,
      );
    })
    .addCase(deleteListItemAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    });
}
