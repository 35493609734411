import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';

import { IDialogueState, StateMan } from 'features/dialogue/dialogueSlice';
import {
  deleteDocument,
  IDocumentCreate,
  postDocument,
} from 'features/document/documentAPI';
import { deleteList } from 'features/list/listAPI';
import { IPhase } from 'features/phase/phaseAPI';
import { deletePoll, IPoll } from 'features/poll/pollAPI';
import { DeepFindObjectType } from 'helpers/finders';
import { findObjectInState, prepareForBackend, sameId } from 'helpers/objects';
import {
  deleteBlock,
  deleteChildBlock,
  IBlock,
  patchBlockData,
  postBlockData,
} from './blockAPI';
import { IChatBlock, IChatBlockCreate, postChatBlock } from './chatBlockAPI';
import {
  IDocumentBlock,
  IDocumentBlockCreate,
  IDocumentBlockCreateWithDocument,
  patchDocumentBlock,
  postDocumentBlock,
} from './documentBlockAPI';
import {
  ILibraryBlock,
  ILibraryBlockCreate,
  postLibraryBlock,
} from './libraryBlockAPI';
import { IListBlock, postListBlock } from './listBlockAPI';
import { emptyListBlock, makeList } from './listBlockSlice';
import { IPollBlock, postPollBlock } from './pollBlockAPI';
import { emptyPollBlock, makePoll } from './pollBlockSlice';
import {
  ISudokuBlock,
  ISudokuBlockCreate,
  patchSudokuBlock,
  postSudokuBlock,
} from './sudokuBlockAPI';

export enum BlockType {
  Chat = 'Chat',
  List = 'List',
  Poll = 'Poll',
  Document = 'Document',
  Library = 'Library',
  Meeting = 'Meeting',
  Sudoku = 'Sudoku',
}
export enum ListType {
  MultiList = 'MultiList',
  ProCon = 'ProCon',
  SWOT = 'SWOT',
  Kanban = 'Kanban',
}
type BlockListType = BlockType | ListType | 'AnyBlock';

export function blockIs(
  block: IBlock,
  typeOnly: boolean = false,
): Record<BlockListType, boolean> {
  const res: any = {};
  let valid = false;
  for (const blocktype in BlockType) {
    if (
      block.childType === blocktype &&
      (typeOnly || !!(block as any)[`child${blocktype}Block`])
    ) {
      valid = true;
      res[blocktype] = true;
    } else res[blocktype] = false;
  }
  if (valid) res['AnyBlock'] = true;
  for (const listtype in ListType) {
    res[listtype] =
      block.childType === BlockType.List &&
      block.childListBlock?.listType === listtype;
  }
  return res;
}
export function listIs(
  list: IListBlock | ListType | string,
): Record<ListType, boolean> {
  const type = typeof list === 'object' ? list.listType : list;
  return {
    [ListType.MultiList]: type === ListType.MultiList,
    [ListType.ProCon]: type === ListType.ProCon,
    [ListType.SWOT]: type === ListType.SWOT,
    [ListType.Kanban]: type === ListType.Kanban,
  };
}

export type ListRecipe = {
  title: string;
  listNameIds: string[];
  editable: boolean;
};
export type ListRecipes = {
  [name in ListType]: ListRecipe;
};
export const ListCookBook: ListRecipes = {
  [ListType.MultiList]: {
    title: 'LISTS.LIST',
    listNameIds: ['LISTS.LIST'],
    editable: true,
  },
  [ListType.ProCon]: {
    title: 'LISTS.PROCON',
    listNameIds: ['LISTS.PROCON_LIST'],
    editable: false,
  },
  [ListType.SWOT]: {
    title: 'LISTS.SWOT',
    listNameIds: [
      'LISTS.SWOT_1',
      'LISTS.SWOT_2',
      'LISTS.SWOT_3',
      'LISTS.SWOT_4',
    ],
    editable: false,
  },
  [ListType.Kanban]: {
    title: 'LISTS.KANBAN',
    listNameIds: ['LISTS.KANBAN_1', 'LISTS.KANBAN_2', 'LISTS.KANBAN_3'],
    editable: false,
  },
};

export const emptyBlock: IBlock = {
  name: '',
  description: '',
  order: '',
  childType: '',
};

export const postBlockAsync = createAsyncThunk(
  'dialogue/postBlockData',
  async (
    {
      data,
      subData,
      stateMan,
      listNames,
      listType,
      poll,
    }: {
      data: IBlock;
      subData?:
        | IChatBlockCreate
        | IListBlock
        | IPollBlock
        | ILibraryBlockCreate
        | IDocumentBlockCreate
        | ISudokuBlockCreate;
      stateMan: StateMan;
      listNames?: string[];
      listType?: ListType;
      poll?: IPoll;
    },
    thunkAPI,
  ) => {
    try {
      let blResponse;
      let chResponse;
      let blid;
      let childBlock = null;
      blResponse = await postBlockData(data);
      blid = blResponse.data._id;
      switch (data.childType) {
        case BlockType.Chat:
          const ccb: IChatBlockCreate = { parent: blid, messages: [] };
          chResponse = await postChatBlock({ ...subData, ...ccb });
          childBlock = { ...ccb, id: chResponse.data._id };
          break;
        case BlockType.Library:
          const clibb: ILibraryBlockCreate = {
            parent: blid,
            files: [],
            links: [],
          };
          chResponse = await postLibraryBlock({ ...subData, ...clibb });
          childBlock = { ...clibb, id: chResponse.data._id };
          break;
        case BlockType.Document:
          let cdocb: IDocumentBlockCreate | IDocumentBlockCreateWithDocument = {
            parent: blid,
            readOnly: false,
          };
          chResponse = await postDocumentBlock({
            ...cdocb,
            ...(subData as IDocumentBlockCreate),
          });

          const doc: IDocumentCreate = {
            block: chResponse.data._id,
            text: {},
            files: [],
          };
          const postDocRes = await postDocument(doc);

          cdocb = {
            ...(subData as IDocumentBlockCreate),
            parent: blid,
            document: { ...doc, id: postDocRes.data._id },
          };
          childBlock = { ...cdocb, id: chResponse.data._id };
          break;
        case BlockType.List:
          const clb: IListBlock = {
            ...emptyListBlock,
            parent: blid,
            listType: listType,
          };
          chResponse = await postListBlock({
            ...(subData as IListBlock),
            ...clb,
          });
          clb.id = chResponse.data._id;
          if (listNames) {
            const promises = listNames.map(async (name, i) => {
              return await makeList(name, i, clb);
            });
            const allres = await Promise.all(promises);
            childBlock = {
              ...clb,
              lists: allres.map((res) => res.data.list),
            };
          }
          break;
        case BlockType.Poll:
          const cpb: IPollBlock = {
            ...emptyPollBlock,
            parent: blid,
          };
          chResponse = await postPollBlock({
            ...(subData as IPollBlock),
            ...cpb,
          });
          cpb.id = chResponse.data._id;
          if (poll) {
            const pResponse = await makePoll(cpb, poll);
            childBlock = {
              ...cpb,
              poll: pResponse.data.poll,
            };
          }
          break;
        case BlockType.Sudoku:
          const sucb: ISudokuBlockCreate = {
            parent: blid,
            solution: '',
            challenge: '',
            attempt: '',
            hints: 0,
          };
          chResponse = await postSudokuBlock({ ...subData, ...sucb });
          childBlock = { ...sucb, id: chResponse.data._id };
          break;
      }
      return { response: blResponse, stateMan, childBlock };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const patchBlockAsync = createAsyncThunk(
  'block/patchBlock',
  async (
    {
      data,
      subData,
      stateMan,
    }: {
      data: IBlock;
      subData?:
        | IChatBlock
        | IListBlock
        | IPollBlock
        | ILibraryBlock
        | IDocumentBlock
        | ISudokuBlock;
      stateMan: StateMan;
    },
    thunkAPI,
  ) => {
    try {
      // console.log('first Level patching');
      switch (data.childType) {
        case BlockType.Document:
          if (data.childDocumentBlock && subData)
            await patchDocumentBlock(
              data.childDocumentBlock.id!,
              subData as IDocumentBlock,
            );
          break;
        case BlockType.Sudoku:
          if (data.childSudokuBlock && subData)
            await patchSudokuBlock(
              data.childSudokuBlock.id!,
              subData as ISudokuBlock,
            );
          break;
      }
      return { response: await patchBlockData(data), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const deleteBlockAsync = createAsyncThunk(
  'block/deleteBlock',
  async (
    {
      block,
      stateMan,
    }: {
      block: IBlock;
      stateMan: StateMan;
    },
    thunkAPI,
  ) => {
    try {
      switch (block.childType) {
        case BlockType.List:
          if (!block.childListBlock?.lists) break;
          const promises = block.childListBlock.lists.map(async (l) => {
            const resList = await deleteList(l.id!);
            if (resList.status >= 300)
              throw new Error('Error removing list from database');
            return resList;
          });
          await Promise.all(promises);
          break;

        case BlockType.Document:
          if (!block.childDocumentBlock?.document) break;
          const doc = block.childDocumentBlock.document;
          const resDoc = await deleteDocument(doc.id!);
          if (resDoc.status >= 300)
            throw new Error('Error removing document from database');
          break;

        case BlockType.Poll:
          if (!block.childPollBlock?.poll) break;
          const resPoll = await deletePoll(block.childPollBlock.poll.id!);
          if (resPoll.status >= 300)
            throw new Error('Error removing poll from database');
          break;
      }
      if (blockIs(block).AnyBlock)
        await deleteChildBlock(block.childType!, getChildBlockId(block));
      return {
        response: await deleteBlock(block.id!),
        stateMan,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

type MoveBlockProps = {
  // toPhaseId: number;
  data: IBlock;
  from: DeepFindObjectType;
  // fromIndex: number;
  to: DeepFindObjectType;
  // toIndex: number;
  stateMan: StateMan;
};

export function addBlockReducers() {
  return {
    moveBlock: (state: any, action: PayloadAction<MoveBlockProps>) => {
      // const { toPhaseId } = action.payload;
      const { data, from, to } = action.payload;
      const stateObj = action.payload.stateMan(state);
      if (stateObj.hasOwnProperty('phases')) {
        const fromPhase: IPhase = stateObj.phases.find(
          (ph: IPhase) => ph.id === (from.phase?.id ?? -1),
        );
        const toPhase: IPhase = stateObj.phases.find(
          (ph: IPhase) => ph.id === (to.phase?.id ?? -1),
        );
        if (
          !(
            fromPhase &&
            fromPhase.blocks &&
            toPhase &&
            toPhase.blocks &&
            data.phase?.id
          )
        ) {
          console.log('Error: insufficient information to move BlockList.');
          return;
        }
        const i = fromPhase.blocks.findIndex((b) => b.id === data.id);
        const bl = i >= 0 ? fromPhase.blocks[i] : null;
        if (!sameId(fromPhase, toPhase)) {
          if (i >= 0) fromPhase.blocks.splice(i, 1);
          toPhase.blocks.push(data);
          data.phase = toPhase;
        }
        if (bl) Object.assign(bl, data);
      }
    },
  };
}

function getChildBlockProps(childType: BlockType, childBlock: any) {
  const props: any = { childType };
  props[`child${childType}Block`] = childBlock;
  return props;
}

export function getChildBlockId(block: IBlock) {
  return (block as any)[`child${block.childType}Block`].id;
}

export function addBlockCases(
  builder: ActionReducerMapBuilder<IDialogueState>,
) {
  builder
    .addCase(postBlockAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(postBlockAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const id = action.payload.response.data._id;
      let phase = null;
      if (stateObj.hasOwnProperty('blocks')) {
        phase = stateObj;
      } else if (stateObj.hasOwnProperty('phases')) {
        phase = stateObj.phases.find(
          (ph: IPhase) => ph.id === action.meta.arg.data.phase?.id,
        );
      }
      if (phase) {
        const bl: IBlock = {
          ...action.meta.arg.data,
          id,
          ...getChildBlockProps(
            action.meta.arg.data.childType as BlockType,
            action.payload.childBlock,
          ),
        } as IBlock;
        phase.blocks.push(bl);
      }
    })
    .addCase(postBlockAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(patchBlockAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(patchBlockAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const id: number = action.meta.arg.data.id || 0;
      const bl = findObjectInState('block', stateObj, id);
      if (bl) {
        const newData = { ...action.meta.arg.data };
        if (bl.childType === BlockType.Document && action.meta.arg.subData)
          newData.childDocumentBlock = {
            ...newData.childDocumentBlock,
            ...prepareForBackend(
              action.meta.arg.subData as IDocumentBlock,
              ['id', 'document', 'parent'],
              true,
            ),
          };
        Object.assign(bl, newData);
      }
    })
    .addCase(patchBlockAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(deleteBlockAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(deleteBlockAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const phase: IPhase = stateObj;
      if (phase && phase.blocks) {
        const blockId = action.meta.arg.block.id;
        phase.blocks.splice(
          phase.blocks.findIndex((block) => block.id === blockId),
          1,
        );
      }
    })
    .addCase(deleteBlockAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    });
}
