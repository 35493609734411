import { setGlobalCSSVar } from 'css/vars';

export const END_POINT = process.env.REACT_APP_BACKEND;
export const PUBLIC_URL = process.env.PUBLIC_URL || '/.';
setGlobalCSSVar('--public-url', PUBLIC_URL);

export const RECONNECT_ATTEMPTS1 = 7; // # of attempts with delay 1
export const RECONNECT_DELAY1 = 1_000; // delay for first number of attempts
export const RECONNECT_NOTIFY = 3; // notify the user after these attempts
export const RECONNECT_ATTEMPTS2 = 20; // total attempts to make
export const RECONNECT_DELAY2 = 10_000; // delay for second number of attempts
export const RECONNECT_DELAY3 = 180_000; // final delay attempts

export const IS_DEV: boolean = process.env.NODE_ENV !== 'production';
export const IS_PRODUCTION: boolean = process.env.NODE_ENV === 'production';

export const SLUGS = {
  WELCOME: 'welcome',
  MYPAGE: 'mypage',
  MYPROJECTS: 'mydialogues',
  DIALOGUE: 'dialogue',
  GUEST: ['guest', 'gast', 'praatmee'],
  // GUEST: 'guest',
  LOGIN: 'login',
  SIGNUP: ['signup', 'inschrijven'],
  JOIN: 'join',
  RESETPWD: 'resetpwd',
  VERIFYMAIL: 'verifymail',
  TRYOUT: 'tryout',
  TEST: 'test',
  // Media
  PROMO: 'promo',
  // Mobile pages
  M: 'm',
  WOO: 'woo',
  WOO_F: 'woo/f',
  WOO_R: 'woo/r',
  // The slugs below are used as filenames in public/html
  FRONT: 'frontpage',
  ABOUT: 'about',
  CONSENT: 'consent',
  CONTACT: 'contact',
  HELP: 'help',
  PRIVACY: 'privacy',
  TOOLS: 'tools',
};

/* eslint-disable no-useless-escape */
export const USERNAME_PATTERN =
  "^(?=[A-zÀ-ÿ]{1}[A-zÀ-ÿ '-]{4,30}[A-zÀ-ÿ]{1}$)(?!.*[ ]{2}).*$";
// "^(?=[a-zA-Zà-üÀ-Ü]{1}[a-zA-Zà-üÀ-Ü '-]{4,30}[a-zA-Zà-üÀ-Ü]{1}$)(?!.*[ ]{2}).*$";
// /^(?=[a-zA-Zà-üÀ-Ü]{1}[a-zA-Zà-üÀ-Ü '-]{4,30}[a-zA-Zà-üÀ-Ü]{1}$)(?!.*[ ]{2}).*$/;
// export const EMAIL_PATTERN =
//   '^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$';
// "^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-]+$";
// /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/;
export const PASSWORD_PATTERN =
  IS_DEV && false ? '.*' : '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$';
// /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
/* eslint-enable no-useless-escape */

export const MODAL_CONTAINER_ID = 'pd_modals';

export const INACTIVE_GUEST_TOLERANCE = 10; // keep the latest 10 inactive guests
