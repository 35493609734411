/* OBJECT FUNCTIONS */

export type IdObject = {
  id: number;
};
export type OrderObject = {
  order: string;
};
export type AnyObject = {
  [key: string]: any;
};
export type AnyIdObject = AnyObject & IdObject;
export type AnyOrderObject = AnyObject & IdObject & OrderObject;

// returns the id, whether o is populated or not
// undefined if no id found
export function idOf(
  o: AnyObject | number | null | undefined,
): number | undefined {
  if (typeof o === 'number') return o;
  if (!o) return undefined;
  if (Object.hasOwn(o, 'id')) return o.id;
  return undefined;
}

export function asNumber(i: string | number): number {
  return typeof i === 'number' ? i : parseInt(i);
}

// checks if what was given is an object with an id
export function hasId(o: AnyObject | number | null | undefined): boolean {
  if (typeof o === 'number') return false;
  if (!o) return false;
  if (Object.hasOwn(o, 'id')) return true;
  return false;
}

// returns equality of given object.id's
// false if one or both are undefined or 0
export function sameId(
  o1: AnyObject | number | undefined,
  o2: AnyObject | number | undefined,
): boolean {
  return idOf(o1) ? idOf(o1) === idOf(o2) : false;
}

// Returns a new unique id that is -1 or smaller,
// given the array of objects with id's.
// These id's are negative to indicate newly added objects
// and should be replaced with db-generated id's later.
export function newId(ids: number[]): number {
  if (!ids.length) return -1;
  return (
    ids.reduce((lowestId, id) => {
      return Math.min(id, lowestId);
    }, 0) - 1
  );
}

// returns a new array with the given replacement substituting the original in the array, based on its id
export function replaceObjectById<T extends Record<'id', any>>(
  objects: T[],
  replacement: T,
): T[] {
  const index = objects.findIndex((obj) => obj.id === replacement.id);
  if (index === -1) {
    return objects;
  } else {
    return [
      ...objects.slice(0, index),
      replacement,
      ...objects.slice(index + 1),
    ];
  }
}

// mutates the array by susbstituting an object with the given replacement, based on its id
export function mutateObjectById<T extends { id?: any }>(
  objects: T[],
  replacement: T,
): void {
  const index = objects.findIndex((obj) => obj.id === replacement.id);
  if (index !== -1) {
    objects.splice(index, 1, replacement);
  }
}

// replaces any prop value that are objects with the id prop of those objects
export function flattenObject<T extends AnyObject>(
  obj: T,
  noArrays?: boolean,
): T {
  const flatObj: AnyObject = {};
  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      if (noArrays) flatObj[key] = undefined;
      else if (value.length > 0) {
        if (idOf(value[0]) !== undefined) {
          // convert array of objects to array of numbers based on 'id' property
          flatObj[key] = value.map((obj) => idOf(obj));
        } else {
          // array of non-id-objects, don't touch
          flatObj[key] = value;
        }
      } else {
        // empty array
        flatObj[key] = value;
      }
    } else if (idOf(value) !== undefined) {
      // keep id of object only
      flatObj[key] = idOf(value);
    } else {
      // copy all other values
      flatObj[key] = value;
    }
  }
  return flatObj as T;
}

// flattens the object and removes props given in the list
export function prepareForBackend<T extends AnyObject, K extends keyof T>(
  obj: T,
  keysToOmit?: K[],
  noArrays?: boolean,
  noUndefined?: boolean,
): Partial<Pick<T, K>> {
  let flatObj = flattenObject(omitProps(obj, keysToOmit || []), noArrays);
  return noArrays || noUndefined ? removeUndefinedProps(flatObj) : flatObj;
}

type Primitive = string | number | boolean | symbol;
export function pickPrimitiveProps(
  obj: Record<string, unknown>,
): Record<string, Primitive> {
  const result: Record<string, Primitive> = {};
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      const value = obj[prop];
      if (
        typeof value !== 'object' ||
        value === null ||
        value instanceof Date
      ) {
        result[prop] = value as Primitive;
      }
    }
  }
  return result;
}

// copies the given list of props of an object into a new object
export function pickProps<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
  const newObj: Partial<Pick<T, K>> = {};
  keys.forEach((key) => {
    newObj[key] = obj[key];
  });
  return newObj as Pick<T, K>;
}

// copies all but the given list of props of an object into a new object
export function omitProps<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
  const newObj: Partial<Pick<T, K>> = {};
  const objKeys = Object.keys(obj as object);
  for (const key of objKeys) {
    if (!keys.includes(key as K)) newObj[key as K] = obj[key as K];
  }
  return newObj as Pick<T, K>;
}

// removes any undefined props from a copy of an object
export function removeUndefinedProps<T extends object>(
  originalObject: T,
): Partial<T> {
  return Object.keys(originalObject).reduce((acc, key) => {
    if (originalObject[key as keyof T] !== undefined) {
      acc[key as keyof T] = originalObject[key as keyof T];
    }
    return acc;
  }, {} as Partial<T>);
}

export function removeUndefinedProps2<T extends object>(obj: T): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined),
  ) as Partial<T>;
}

// merges the props of object2 that are not undefined into object 1
export function mergeObjects<T extends object, U extends Partial<T>>(
  object1: T,
  object2: U,
): T {
  return Object.assign(
    object1,
    removeUndefinedProps({ ...object2, id: undefined }),
  );
}

// removes objects with a given id from the array
export function removeObjectWithId<T extends AnyObject>(arr: T[], id: number) {
  return arr.filter((v) => v.id === id);
}

// removes duplicate objects from the array, based on id
export function removeDuplicateIds<T extends AnyObject>(arr: T[]) {
  return arr.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
}

// removes duplicate objects from the array, based on id
export function removeNonUniqueIds<T extends AnyObject>(arr1: T[], arr2: T[]) {
  return arr1.filter((v) => arr2.findIndex((v2) => v2.id === v.id));
}

export function toArrayOfIdObject<T extends AnyObject>(
  arr?: Array<T | number>,
) {
  if (!arr) return undefined;
  return arr.map((obj) => {
    return { id: typeof obj === 'object' ? (obj.id ?? 0) : (obj ?? 0) };
  });
}

// tests if a given array only has objects with an id prop,
// also true for an empty array, but false for undefined
export function isArrayOfAnyIdObject(arr: any): boolean {
  return (
    arr &&
    Array.isArray(arr) &&
    arr.every(
      (item) => item && typeof item === 'object' && item.hasOwnProperty('id'),
    )
  );
}

// Update only some properties of an object (without knowing the incoming/new key names)
export function updateObject(originalObject: any, updates: any) {
  // Deep copy
  // const clonedObject = JSON.parse(JSON.stringify(originalObject));

  // Shallow copy
  const clonedObject = Object.assign({}, originalObject);

  for (const key in updates) {
    if (updates.hasOwnProperty(key)) {
      if (
        typeof updates[key] === 'object' &&
        updates[key] !== null &&
        !Array.isArray(updates[key])
      ) {
        clonedObject[key] = updateObject(clonedObject[key], updates[key]);
      } else {
        clonedObject[key] = updates[key];
      }
    }
  }

  return clonedObject;
}

export function updateObjectDirectly(originalObject: any, updates: any) {
  for (const key in updates) {
    if (updates.hasOwnProperty(key)) {
      if (
        typeof updates[key] === 'object' &&
        updates[key] !== null &&
        !Array.isArray(updates[key]) &&
        typeof originalObject[key] === 'object' &&
        originalObject[key] !== null &&
        !Array.isArray(originalObject[key])
      ) {
        updateObject(originalObject[key], updates[key]);
      } else {
        originalObject[key] = updates[key];
      }
    }
  }
}

// These are search paths to findObjectInState.
// Keys are the types of objects to look for.
// The array starts with a prop of the object to find, that
// can be used to identify that type of object,
// and then contains props of the given state to search in.
const paths = {
  phase: ['blocks', 'phases'],
  block: ['childType', 'phases', 'blocks'],
  list: ['listItems', 'phases', 'blocks', 'childListBlock', 'lists'],
  listitem: [
    'coordinate_x',
    'phases',
    'blocks',
    'childListBlock',
    'lists',
    'listItems',
  ],
  test: [
    'content',
    'phases',
    'blocks',
    'childListBlock',
    'lists',
    'listItems',
    'message',
  ],
  listmessage: [
    'content',
    'phases',
    'blocks',
    'childListBlock',
    'lists',
    'listItems',
    'message',
    'replies',
  ],
};

// This function finds an object in the given state (redux or normal variable),
// using the object's id and one of the above defined path types,
// When calling this function, leave the last parameter undefined, it is used
// only for recursive calling.
// TODO: We should not be needing this function, only as a failsafe. So, we need
// to find all calls to it, make sure the stateMan provided is correct from the source,
// and then only add a call to this function as a last resort.
export function findObjectInState(
  type: keyof typeof paths, // one of the props of paths
  state: any, // the top variable (e.g., from redux) to start searching from
  id: number, // the id of the object to find
  path?: string[], // leave undefined
): AnyIdObject | undefined {
  const p = path || paths[type as keyof typeof paths];
  if (!state || !state.id || p.length === 0) {
    return undefined;
  }
  let found: any = undefined;
  // the first element in the path is always the prop that is used to identify
  // the type of object to be found
  const identifyingProp = p[0];
  if (state.hasOwnProperty(identifyingProp)) {
    // matching type of object, now check the id
    if ((state as any).id === id) found = state as any;
    else if ((state as any)[identifyingProp].id === id)
      found = (state as any)[identifyingProp];
    if (found) return found;
  }
  // start looking at the second prop from the path
  const currentProp = p[1];
  if (!currentProp) return undefined;
  let nextState, pp;
  if (state.hasOwnProperty(currentProp) && state[currentProp]) {
    // state prop found and with value
    if (Array.isArray((state as any)[currentProp])) {
      // prop is an array: look in its elements
      found = (state as any)[currentProp].find(
        (obj: AnyIdObject) =>
          obj.id === id && obj.hasOwnProperty(identifyingProp),
      );
      if (found) return found;
      // nothing found, move the state down the hierarchy
      // nextState is an array
      nextState = state[currentProp];
    }
    // the prop is not an array, just a next step in the hierarchy, move down the state
    // but make sure nextState is an array
    else {
      found = state[currentProp].id === id ? state[currentProp] : undefined;
      if (found) return found;
      nextState = state[currentProp] ? [state[currentProp]] : undefined;
    }
  } else {
    // prop does not exist on the state or is nullish
    // skip to the next prop in the path
    pp = p.slice(2);
    pp.unshift(identifyingProp); // keep the first prop in place
    // recursively look with the next prop in the path
    found = findObjectInState(type, state, id, pp);
    if (found) return found;
  }
  // nothing found at this level in the hierarchy,
  // look into all elements of the next level, using the next prop in the path
  pp = p.slice(2);
  pp.unshift(identifyingProp);
  if (nextState)
    // iterate over all elements of the next level
    for (const s of nextState) {
      found = findObjectInState(type, s, id, pp);
      if (found) return found;
    }
  // nothing found...
  return undefined;
}
