import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';
import { Retrieving, callGet } from 'helpers/apiTypes';
import { prepareForBackend } from 'helpers/objects';

export type IBlock = API['Block']['read'];
export type IBlockCreate = API['Block']['create'];
type IBlockUpdate = API['Block']['update'] & {};

function prepareForUpdate(block: IBlock): IBlockUpdate {
  const updated = prepareForBackend(
    block,
    [
      'id',
      'childChatBlock',
      'childListBlock',
      'childDocumentBlock',
      'childLibraryBlock',
      'childPollBlock',
      'childSudokuBlock',
    ],
    true, // ignore array-attributes and attributes with value undefined
  ) as IBlockUpdate;
  return updated;
}
function prepareForCreate(block: IBlock): IBlockCreate {
  return prepareForBackend(block, [
    // TODO: remove more attributes?
    'id',
  ]) as IBlockCreate;
}

export const getBlockAndRelatedData = async (id: number) => {
  try {
    const query = {
      '*': true,
      phase: {
        id: true,
      },
      childChatBlock: {
        '*': true,
        messages: {
          '*': true,
          author: {
            '*': true,
            avatar: {
              uri: true,
            },
          },
          likes: {
            '*': true,
            liker: {
              id: true,
              username: true,
            },
          },
          replyTo: {
            '*': true,
            author: {
              '*': true,
              avatar: {
                uri: true,
              },
            },
          },
          files: {
            '*': true,
          },
        },
      },
      childLibraryBlock: {
        '*': true,
        files: {
          '*': true,
          author: {
            '*': true,
          },
        },
      },
      childListBlock: {
        '*': true,
        lists: {
          '*': true,
          parent: {
            id: true,
            listType: true,
          },
          listItems: {
            '*': true,
            message: {
              '*': true,
              author: {
                '*': true,
                avatar: {
                  uri: true,
                },
              },
              likes: {
                '*': true,
                liker: {
                  id: true,
                  username: true,
                },
              },
              replyTo: {
                id: true,
              },
              replies: {
                '*': true,
                author: {
                  '*': true,
                  avatar: {
                    uri: true,
                  },
                },
                replyTo: {
                  id: true,
                },
              },
              files: {
                '*': true,
              },
            },
          },
        },
      },
      childPollBlock: {
        '*': true,
        poll: {
          '*': true,
          options: {
            '*': true,
            message: {
              '*': true,
              files: {
                '*': true,
              },
              author: {
                '*': true,
                avatar: {
                  uri: true,
                },
              },
            },
            votes: {
              '*': true,
              voter: {
                id: true,
              },
            },
          },
        },
      },
      childDocumentBlock: {
        '*': true,
        document: {
          '*': true,
        },
      },
    } as const satisfies Retrieving<'Block'>;

    const response = await callGet('Block', query, id);

    return response.response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const postBlockData = async (data: IBlock) => {
  try {
    const response: any = await api.post('Block', prepareForCreate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchBlockData = async (data: IBlock) => {
  try {
    // console.log('api call will be made');
    const response: any = await api.patch(
      'Block/' + data.id,
      prepareForUpdate(data),
    );
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const deleteBlock = async (id: number) => {
  try {
    const response = await api.delete('Block/' + id);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const deleteChildBlock = async (childType: string, id: number) => {
  try {
    const response = await api.delete(`${childType}Block/` + id);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
