import {
  BaseEventPayload,
  ElementDragType,
} from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';
import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import classNames from 'classnames';
import {
  FileChild,
  Paragraph,
  TabStopPosition,
  TabStopType,
  Table,
  TableBorders,
  TableRow,
  TextRun,
  WidthType,
} from 'docx';
import {
  CSSProperties,
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import Collapsable from 'components/collapsable/Collapsable';
import { useDialogueContext } from 'components/dialogues/DialogueView';
import FileUpload from 'components/files/FileUpload';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import Message, {
  MessageToPdf,
  messageToDocx,
} from 'components/messages/Message';
import MiniMessage, {
  MiniMessageToPdf,
  miniMessageToDocx,
} from 'components/messages/MiniMessage';
import TextAreaInput, {
  justWhitespace,
} from 'components/messages/TextAreaInput';
import DropMenu from 'components/navigation/DropMenu';
import ScrollObserver from 'components/scrollobserver/ScrollObserver';
import {
  SortableContainer,
  SortableItem,
  SortableItemType,
  SortableList,
  SortableListInfo,
  SortableListType,
} from 'components/sortables/Sortable';
import Author from 'components/user/Author';
import { IBlock } from 'features/block/blockAPI';
import { ListType, listIs } from 'features/block/blockSlice';
import {
  DialogueStateProps,
  StateMan,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { restrictedImageFileTypes } from 'features/files/fileSlice';
import { IList } from 'features/list/listAPI';
import { IListItem } from 'features/list/listItemAPI';
import {
  patchListItemAsync,
  postListItemAsync,
} from 'features/list/listItemSlice';
import { IMessage } from 'features/message/messageAPI';
import { postMessageAsync } from 'features/message/messageSlice';
import { IUser } from 'features/user/userAPI';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { IS_DEV } from 'helpers/consts';
import { ExportProps } from 'helpers/export';
import { AnyOrderObject, idOf, sameId } from 'helpers/objects';
import { getOrderUsingIndex, sortByOrder, sortByTime } from 'helpers/sorting';

import { WooScoreView, getWooScore } from 'components/woo/WooForm';
import styles from 'css/pdfStyles';
import ListCanvas from './ListCanvas';

import '../woo/Woo.scss';
import './List.scss';

interface IListContentProps {
  block: IBlock;
  scrollableRef: React.RefObject<HTMLDivElement>;
  showDescription?: boolean;
  list: IList;
  className?: string;
  lastVisited: Date | null;
  stateMan: StateMan;
  showLikes: boolean;
  showColors: boolean;
  showAttribution: boolean;
}

function UnconnectedListContent(
  props: IListContentProps & UserStateProps & DialogueStateProps,
) {
  const {
    block,
    list,
    stateMan,
    showLikes,
    showColors,
    showAttribution,
    className,
    lastVisited,
    scrollableRef,
    user,
    userCanEdit,
    dialogue,
  } = props;
  const { reorderListItems } = useDialogueContext();

  const listInfo: SortableListInfo = {
    id: list.id as number,
    listType: SortableListType.Items,
    subListType: (list.parent?.listType as ListType) || ListType.MultiList,
    // items: sortByOrder(list?.listItems as AnyOrderObject[]),
    items: list.listItems as AnyOrderObject[],
    canvas: block.childListBlock?.canvas ?? false,
    locked: (block.locked || block.postOnly) ?? false,
  };

  return list ? (
    block.childListBlock?.canvas ? (
      <ListCanvas
        block={block}
        lastVisited={lastVisited}
        listInfo={listInfo}
        // listItems={listInfo.items}
        // listType={ListType.MultiList}
        // onUpdate={() => {}}
        showLikes={showLikes}
        showColors={showColors}
        stateMan={stateMan}
        // showDescription={true}
        showAttribution={showAttribution}
        // lists={[list]}
        // scrollableRef={scrollableRef}
      />
    ) : (
      <div
        className={classNames(className, {
          attribution: showAttribution,
        })}
        ref={scrollableRef}
      >
        <SortableContainer reorder={reorderListItems} blockId={block.id}>
          <SortableList
            key={listInfo.id}
            listInfo={listInfo}
            accepting={[SortableItemType.Item, SortableItemType.Message]}
            copySource={(block.locked || block.postOnly) ?? false}
            disabled={(block.locked || block.postOnly) ?? false}
            gap={8}
            containerRef={scrollableRef}
            blockId={block.id}
          >
            {(
              sortByOrder(list.listItems as AnyOrderObject[]) as IListItem[]
            ).map((listItem, i) => {
              return (
                <ListItem
                  key={listItem.id}
                  listItem={listItem}
                  listInfo={listInfo}
                  index={i}
                  block={block}
                  user={user}
                  canPin={userCanEdit(dialogue)}
                  showLikes={showLikes}
                  showColors={showColors}
                  showAttribution={showAttribution}
                  lastVisited={lastVisited}
                  containerRef={scrollableRef}
                  stateMan={stateMan}
                />
              );
            })}
          </SortableList>
        </SortableContainer>
      </div>
    )
  ) : (
    <div>
      <FormattedMessage id="DIALOGUE.VIEW.NO_CONTENT" />
    </div>
  );
}

const ListContent = connect(mapUserStateToProps)(
  connect(mapDialogueStateToProps)(UnconnectedListContent),
);

export function ListToPdf(
  props: {
    block: IBlock;
    showDescription?: boolean;
    list: IList;
    index: number;
    showLikes: boolean;
    showColours: boolean;
    showAttribution: boolean;
    style: Style;
  } & ExportProps,
) {
  const {
    dialogue,
    block,
    list,
    index,
    showLikes,
    showColours,
    showAttribution,
    intl,
    style,
  } = props;
  const listinfo: SortableListInfo = {
    id: list.id as number,
    listType: SortableListType.Items,
    subListType: (list.parent?.listType as ListType) || ListType.MultiList,
    items: sortByOrder(list?.listItems as AnyOrderObject[]),
    canvas: false,
    locked: block.locked ?? false,
  };
  // const listIcons = getListIcons(block.childListBlock?.listType);
  const listName = getListName(
    block.childListBlock?.listType,
    list,
    index,
    intl,
  );
  return list ? (
    <View style={[styles.list, style]}>
      <View style={styles.list_header}>
        {listName.split(/[/|,-]/).map((name, i, a) =>
          i === 0 || i === a.length - 1 ? (
            <Text key={i} style={i > 0 ? styles.list_header_2 : {}}>
              {name.trim()}
            </Text>
          ) : null,
        )}
      </View>
      {(listinfo.items as IListItem[]).map((listItem, i) => {
        return (
          <ListItemToPdf
            key={listItem.id}
            listItem={listItem}
            alt={listItem.alt ?? ''}
            block={block}
            showLikes={showLikes}
            showColours={showColours}
            showAttribution={showAttribution}
            intl={intl}
            dialogue={dialogue}
          />
        );
      })}
    </View>
  ) : (
    <View>
      <Text>{intl.formatMessage({ id: 'DIALOGUE.VIEW.NO_CONTENT' })}</Text>
    </View>
  );
}

export async function listToDocx(
  props: {
    block: IBlock;
    showDescription?: boolean;
    list: IList;
    index: number;
    showLikes: boolean;
    showColours: boolean;
    showAttribution: boolean;
    style: string;
  } & ExportProps,
): Promise<FileChild[]> {
  const {
    dialogue,
    block,
    list,
    index,
    showLikes,
    showColours,
    showAttribution,
    style,
    intl,
  } = props;
  const promise = new Promise<FileChild[]>(async (resolve, reject) => {
    const listinfo: SortableListInfo = {
      id: list.id as number,
      listType: SortableListType.Items,
      subListType: (list.parent?.listType as ListType) || ListType.MultiList,
      items: sortByOrder(list?.listItems as AnyOrderObject[]),
      canvas: false,
      locked: block.locked ?? false,
    };
    // const listIcons = getListIcons(block.childListBlock?.listType);
    const listName = getListName(
      block.childListBlock?.listType,
      list,
      index,
      intl,
    );
    const res: FileChild[] = [];
    const rows: TableRow[] = [];
    if (listName)
      res.push(
        new Paragraph({
          children: listName
            .split(/[/|,-]/)
            .map((name, i, a) =>
              i === 0 || i === a.length - 1
                ? new TextRun({
                    text: (i > 0 ? '\t' : '') + name.trim(),
                  })
                : null,
            )
            .filter((t) => t !== null) as TextRun[],
          style: 'listHeader',
          spacing: {
            before: index === 0 ? 3 * 20 : undefined,
          },
          tabStops: [{ type: TabStopType.END, position: TabStopPosition.MAX }],
          indent: {
            left: showAttribution ? 60 * 20 : undefined,
          },
        }),
      );
    for (const [i, item] of (listinfo.items ?? []).entries()) {
      rows.push(
        await listItemToDocx({
          listItem: item,
          alt: item.alt ?? '',
          block,
          showLikes,
          showColours,
          showAttribution,
          intl,
          dialogue,
        }),
      );
    }
    if (list && rows && rows.length)
      res.push(
        new Table({
          width: { size: '100%', type: WidthType.PERCENTAGE },
          borders: TableBorders.NONE,
          rows: rows,
        }),
      );
    resolve(res);
  });
  return promise;
}

export type ListItemProps = {
  listItem: IListItem;
  listInfo: SortableListInfo;
  index: number;
  block: IBlock;
  user?: IUser;
  canPin?: boolean;
  showLikes: boolean;
  showColors: boolean;
  showAttribution: boolean;
  lastVisited: Date | null;
  containerRef?: React.RefObject<HTMLDivElement>;
  style?: CSSProperties;
  withHandle?: boolean;
  noDropIndicator?: boolean;
  onDrag?: (args: BaseEventPayload<ElementDragType>) => void;
  stateMan: StateMan;
};

export function ListItem(props: ListItemProps) {
  const {
    listItem,
    listInfo,
    index,
    block,
    user,
    canPin = false,
    showLikes,
    showColors,
    showAttribution,
    lastVisited,
    containerRef,
    style,
    withHandle = true,
    noDropIndicator = false,
    stateMan,
  } = props;
  const isProCon = listInfo.subListType
    ? listIs(listInfo.subListType).ProCon
    : false;
  const isSwot = listInfo.subListType
    ? listIs(listInfo.subListType).SWOT
    : false;
  const [replyTo, setReplyTo] = useState<IMessage | null>(null);
  const [open, setOpen] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  function renderExtraContent(
    message?: IMessage,
    extraContentClassName?: string,
  ) {
    switch (listItem.alt) {
      case 'WOO':
        if (listItem?.message?.files?.length) {
          const score = getWooScore(
            listItem.message.files[0].description ?? '',
          );
          return <WooScoreView score={score} initem />;
        }
        break;
      default:
        break;
    }
    return null;
  }

  function startReply(message: IMessage | null) {
    setReplyTo(message);
    setOpen(true);
  }

  function handlePinItem() {
    const unpinnedItems = listInfo.items.filter((i) => !i.pinned);
    const firstunpinned = listInfo.items.length - unpinnedItems.length;
    const order = getOrderUsingIndex(
      sortByOrder(listInfo.items),
      -1,
      firstunpinned,
      true,
    );
    dispatch(
      patchListItemAsync({
        data: {
          id: listItem.id,
          pinned: !listItem.pinned,
          order: order,
        },
        stateMan: stateMan,
      }),
    );
  }

  function renderMessage() {
    if (!user) return null;
    return (
      <Message
        className={'sortable_content'}
        message={listItem.message as IMessage}
        block={block}
        // extraContent={(message?: IMessage) => {
        //   return <div>{listItem.id + ' ' + listItem.order}!</div>;
        //   // return <div>{listItem.message?.replies?.length} replies</div>;
        // }}
        extraContent={renderExtraContent}
        listItemId={listItem.id}
        setNewReplyTo={startReply}
        stateMan={stateMan}
        showLikes={showLikes}
        showColours={showColors}
        showAttribution={showAttribution}
        isNew={
          (listItem.message?.time &&
            lastVisited &&
            new Date(lastVisited) < new Date(listItem.message.time)) ??
          true
        }
        blockRef={containerRef}
        canPin={canPin}
        pinned={listItem.pinned ?? undefined}
        handlePin={handlePinItem}
      >
        <MessageReplies
          message={listItem.message}
          block={block}
          replyTo={replyTo}
          setReplyTo={startReply}
          open={open}
          setOpen={setOpen}
          user={user}
          stateMan={stateMan}
          lastVisited={lastVisited}
          showAttribution={showAttribution}
        />
      </Message>
    );
  }

  function postDropAnimation(element: Element) {
    element.animate(
      [
        {
          backgroundColor: 'rgb(250, 255, 250)',
        },
        {},
      ],
      {
        duration: 1000,
        easing: 'cubic-bezier(0.25, 0.1, 0.25, 1.0)',
        iterations: 1,
      },
    );
  }

  if (!listItem.id || !user) return null;
  return (
    <SortableItem
      key={listItem.id}
      disabled={listItem.pinned ?? false}
      copySource={(block.locked || block.postOnly) ?? false}
      listInfo={listInfo}
      item={listItem as AnyOrderObject}
      itemType={SortableItemType.Item}
      accepting={[SortableItemType.Item, SortableItemType.Message]}
      className={classNames('list_item', listItem.alt ?? '', {
        byme: sameId(user.id, listItem.message?.author),
        is_new:
          (listItem.message?.time &&
            lastVisited &&
            new Date(lastVisited) < new Date(listItem.message.time)) ??
          true,
        pinned: listItem.pinned,
      })}
      index={index}
      onCanvas={listInfo.canvas}
      withHandle={withHandle}
      noAnimation={isProCon}
      gap={8}
      containerRef={containerRef}
      blockId={block.id}
      postDropAnimation={(ref) => {
        const elem = ref.current?.querySelector('.sortable_content');
        if (elem) postDropAnimation(elem);
      }}
      noDropIndicator={noDropIndicator}
      onDrag={props.onDrag}
      style={style}
      data-pd-item={IS_DEV ? listItem.id : undefined}
    >
      {renderMessage()}
    </SortableItem>
  );
}

function ListItemToPdf(
  props: {
    listItem: IListItem;
    alt?: string;
    block: IBlock;
    showLikes: boolean;
    showColours: boolean;
    showAttribution: boolean;
  } & ExportProps,
) {
  const {
    dialogue,
    block,
    listItem,
    showLikes,
    showColours,
    showAttribution,
    intl,
    alt,
  } = props;
  return (
    <View
      style={[
        alt === 'pro' ? styles.pro : alt === 'con' ? styles.con : {},
        styles.list_message_wrapper,
      ]}
      minPresenceAhead={20}
    >
      <MessageToPdf
        message={listItem.message as IMessage}
        block={block}
        // extraContent={(message?: IMessage) => {
        //   // return <div>{listItem.id + ' ' + listItem.order}</div>;
        //   return <div>{listItem.message?.replies?.length} replies</div>;
        // }}
        listItemId={listItem.id}
        alt={listItem.alt ?? ''}
        showLikes={showLikes}
        showColours={showColours}
        showAttribution={showAttribution}
        pinned={listItem.pinned ?? undefined}
        intl={intl}
        dialogue={dialogue}
      >
        <MessageRepliesToPdf
          message={listItem.message}
          hideAuthor={!showAttribution}
          block={block}
          intl={intl}
          dialogue={dialogue}
        />
      </MessageToPdf>
    </View>
  );
}

async function listItemToDocx(
  props: {
    listItem: IListItem;
    alt?: string;
    block: IBlock;
    showLikes: boolean;
    showColours: boolean;
    showAttribution: boolean;
  } & ExportProps,
) {
  const {
    dialogue,
    block,
    listItem,
    showLikes,
    showColours,
    showAttribution,
    intl,
    alt,
  } = props;
  return messageToDocx({
    message: listItem.message!,
    listItemId: listItem.id,
    alt: listItem.alt ?? '',
    block,
    showLikes,
    showColours,
    showAttribution,
    intl,
    dialogue,
    children: messageRepliesToDocx({
      dialogue,
      block,
      message: listItem.message,
      hideAuthor: !showAttribution,
      intl,
    }),
  });
}

type MessageRepliesProps = {
  message?: IMessage;
  block: IBlock;
  replyTo: IMessage | null;
  setReplyTo: (m: IMessage | null) => void;
  lastVisited: Date | null;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  user: IUser;
  showAttribution: boolean;
  stateMan: StateMan;
};

function MessageReplies(props: MessageRepliesProps) {
  const {
    message,
    block,
    replyTo,
    setReplyTo,
    lastVisited,
    open,
    setOpen,
    user,
    showAttribution = true,
    stateMan,
  } = props;
  const [chatMsg, setChatMsg] = useState<string>('');
  const dispatch = useAppDispatch();
  const [showSend, setShowSend] = useState(false);
  const intl = useIntl();

  function handleBlur(e?: React.FocusEvent) {
    if (
      e &&
      e.currentTarget.parentElement?.parentElement?.contains(e.relatedTarget)
    )
      return;
    setChatMsg('');
    setReplyTo(null);
    setShowSend(false);
  }

  function handleMsgChange(input: string, hasText: boolean) {
    setShowSend(hasText);
    setChatMsg(input);
  }

  function submitReplyMessage() {
    if (!replyTo || !chatMsg) return;
    dispatch(
      postMessageAsync({
        data: {
          content: chatMsg,
          edited: false,
          time: new Date(),
          author: user.id!,
          replyTo: replyTo,
          checked: false,
          color: 'none',
          likes: [],
          replies: [],
          files: [],
        },
        author: user,
        stateMan: props.stateMan,
      }),
    ).then((response: any) => {
      switch (response.payload?.response.status) {
        case 201:
          break;
        default:
          console.log(
            response,
            response?.payload.response.status,
            response?.payload.response.data?.message,
          );
      }
      setChatMsg('');
      setShowSend(false);
      setReplyTo(null);
    });
  }

  return replyTo || message?.replies?.length ? (
    <Collapsable className="msg_replies" dimension="height">
      <Collapsable.Content open={open} dimension="height">
        {sortByTime(message?.replies || []).map(
          (reply: IMessage, i: number, a: Array<IMessage>) => {
            let prevAuthor = i > 0 ? idOf(a[i - 1].author) : 0;
            return (
              <MiniMessage
                key={reply.id}
                message={reply}
                block={block}
                stateMan={stateMan}
                showLikes={false}
                prevAuthor={prevAuthor}
                showAttribution={showAttribution}
                isNew={
                  (reply.time &&
                    lastVisited &&
                    new Date(lastVisited) < new Date(reply.time)) ??
                  true
                }
              />
            );
          },
        )}
        {replyTo && sameId(replyTo, message) ? (
          <div className="msg_tail">
            <div className="msg_input">
              <TextAreaInput
                onChange={handleMsgChange}
                onSubmit={submitReplyMessage}
                onBlur={handleBlur}
                placeholder={intl.formatMessage({
                  id: 'CHAT.NEW_MSG',
                })}
                value={chatMsg}
              />
            </div>
            {showSend ? (
              <div
                tabIndex={0}
                className="msg_input_send"
                onClick={submitReplyMessage}
              />
            ) : null}
          </div>
        ) : null}
      </Collapsable.Content>
      <Collapsable.Controller
        open={open}
        setOpen={setOpen}
        className="label"
        icon={IconSymbol.down}
        closeIcon={IconSymbol.up}
        iconSize={16}
        noPlus={true}
      ></Collapsable.Controller>
    </Collapsable>
  ) : null;
}

function MessageRepliesToPdf(
  props: {
    message?: IMessage;
    block: IBlock;
    hideAuthor: boolean;
  } & ExportProps,
) {
  const { dialogue, message, hideAuthor, intl } = props;

  return message?.replies?.length ? (
    <View style={styles.message_replies}>
      {sortByTime(message?.replies || []).map(
        (reply: IMessage, i: number, a: Array<IMessage>) => {
          return (
            <MiniMessageToPdf
              key={reply.id}
              message={reply}
              hideAuthor={hideAuthor}
              intl={intl}
              dialogue={dialogue}
            />
          );
        },
      )}
    </View>
  ) : null;
}

function messageRepliesToDocx(
  props: {
    message?: IMessage;
    block: IBlock;
    hideAuthor?: boolean;
  } & ExportProps,
): Paragraph[] {
  const { dialogue, message, hideAuthor = false, intl } = props;
  const paras: Paragraph[] = [];

  if (message?.replies?.length)
    sortByTime(message?.replies || []).forEach(
      (reply: IMessage, i: number, a: Array<IMessage>) => {
        paras.push(
          ...miniMessageToDocx({
            message: reply,
            hideAuthor: hideAuthor,
            intl: intl,
            dialogue: dialogue,
          }),
        );
      },
    );
  return paras;
}

type ListProps = {
  block: IBlock;
  list: IList;
  index: number;
  showDescription?: boolean;
  showLikes: boolean;
  showColors: boolean;
  showAttribution: boolean;
  lastVisited: Date | null;
  // blockRef?: React.RefObject<HTMLDivElement>;
  stateMan: StateMan;
};

function getListName(
  listType: string | undefined,
  list: IList,
  index: number,
  intl: IntlShape,
) {
  const listName =
    list.name ||
    (listType
      ? listIs(listType).ProCon
        ? intl.formatMessage({ id: 'LISTS.PROCON_LIST' })
        : listIs(listType).SWOT
          ? intl.formatMessage({ id: `LISTS.SWOT_${index + 1}` })
          : listIs(listType).Kanban
            ? intl.formatMessage({ id: `LISTS.KANBAN_${index + 1}` })
            : ''
      : '');
  return listName;
}

function getListIcons(listType: string | undefined, variant?: IconVariant) {
  let iconSymbols: IconSymbol[] = listType
    ? listIs(listType).Kanban
      ? [IconSymbol.todo, IconSymbol.doing, IconSymbol.done]
      : listIs(listType).ProCon
        ? [IconSymbol.smiling, IconSymbol.sad]
        : listIs(listType).SWOT
          ? [
              IconSymbol.strength,
              IconSymbol.weakness,
              IconSymbol.opportunity,
              IconSymbol.threat,
            ]
          : []
    : [];
  const listIcons = iconSymbols.map((sym) => (
    <Icon
      key={sym}
      symbol={sym}
      size={20}
      className={'list_icon'}
      variant={variant}
    />
  ));
  return listIcons;
}

export default function List(props: ListProps) {
  const {
    block,
    list,
    index,
    showLikes,
    showColors,
    showAttribution,
    lastVisited,
  } = props;
  const intl = useIntl();
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [newReplyTo, setNewReplyTo] = useState<IMessage | null>(null);
  const [hasNewMsg, setHasNewMsg] = useState<boolean>(false);

  // TODO: in all stateMan functions, make sure to make them safe.
  // Like in here we should not call o.lists without any checks!
  function stateMan(obj: any): any {
    const o = props.stateMan(obj);
    return o?.lists?.find((l: any) => l.id === list?.id);
  }

  const listName = getListName(
    block.childListBlock?.listType,
    list,
    index,
    intl,
  );
  const listIcons = getListIcons(block.childListBlock?.listType);
  return (
    <div className="list_container">
      <ScrollObserver
        scrollableRef={scrollableRef}
        toBottom={true}
        mutations={true}
        customScrollbar={true}
        shouldScrollToBottom={hasNewMsg}
        noNew={true}
      >
        {listName ? (
          <div className="list_head">
            {listName.split(/[/|,-]/).map((name, i, a) =>
              i === 0 || i === a.length - 1 ? (
                <div className="list_name" key={i}>
                  {listIcons ? listIcons[a.length > 1 ? i : index] : null}
                  <div>{name.trim()}</div>
                  {/* {i > 0 && listIcons ? listIcons[i] : null} */}
                </div>
              ) : null,
            )}
          </div>
        ) : null}
        <ListContent
          block={block}
          // The div that contains the sortable items must be the one that scrolls.
          // So, the scrollableRef must be set on the same div
          // that contains the sortable items.
          // If it is set on a scrolling parent div, an extra div for the scrolling,
          // dnd will not work properly for the lower part of the list.
          scrollableRef={scrollableRef}
          stateMan={stateMan}
          list={list}
          className="list_content items"
          showLikes={showLikes}
          showColors={showColors}
          showAttribution={showAttribution}
          lastVisited={lastVisited}
        />
      </ScrollObserver>
      {!block.locked ? (
        <div className="list_tail">
          <ListTail
            list={list}
            listType={
              (block.childListBlock?.listType as ListType) || ListType.MultiList
            }
            setNewReplyTo={setNewReplyTo}
            newReplyTo={newReplyTo}
            setHasNewMsg={setHasNewMsg}
            stateMan={stateMan}
          />
        </div>
      ) : null}
    </div>
  );
}

type ListTailProps = {
  list: IList;
  listType: ListType;
  newReplyTo: IMessage | null;
  setNewReplyTo: Dispatch<SetStateAction<IMessage | null>>;
  setHasNewMsg: Dispatch<SetStateAction<boolean>>;
  stateMan: StateMan;
};

function UnconnectedListTail(props: ListTailProps & UserStateProps) {
  const { list, listType, user, userId, setHasNewMsg } = props;
  const [chatMsg, setChatMsg] = useState<string>('');
  const [showSend, setShowSend] = useState(false);
  const [proTab, setProTab] = useState(true);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [file, setFile] = useState<File | undefined>(undefined); // for uploading image
  const [previewSrc, setPreviewSrc] = useState<string | null>(null); // state for storing previewImage
  const uploadTrigger = useRef<MouseEventHandler | undefined>(undefined);
  const submitting = useRef<boolean>(false);

  useEffect(() => {
    submitting.current = false;
    setShowSend(!justWhitespace(chatMsg) || !!previewSrc);
  }, [chatMsg, previewSrc]);

  function handleMsgChange(input: string, hasText: boolean) {
    if (!submitting.current) {
      setShowSend(hasText);
      setChatMsg(input);
    }
  }

  const submitMessage = () => {
    if (!(chatMsg || file) || submitting.current) return;
    submitting.current = true;
    if (list && list.id && (chatMsg || file)) {
      // update the order attribute
      // TODO: this is not the best way to get the order,
      //       we should let the order be determined by the backend
      const order = getOrderUsingIndex(
        sortByOrder(list.listItems as AnyOrderObject[]),
        -1, // -1 indicates moving between lists (or adding to a list)
        -1, // -1 indicates adding to the end of the list
      );
      dispatch(
        postListItemAsync({
          data: {
            content: chatMsg || '',
            edited: false,
            time: new Date(),
            author: userId,
            replyTo: null, //replyTo ? replyToMsg ? replyToMsg : null : null,
            checked: false,
            color: 'none',
            likes: [],
            replies: [],
            files: [],
          },
          listId: list.id,
          order: order,
          author: user,
          file: file,
          alt:
            list.parent && listIs(list.parent).ProCon
              ? proTab
                ? 'pro'
                : 'con'
              : undefined,
          stateMan: props.stateMan,
        }),
      ).then((response: any) => {
        switch (response.payload?.response.status) {
          case 200:
          case 201:
            break;
          default:
            console.log(response, response.status, response?.data?.message);
        }
        setHasNewMsg(true);
        setTimeout(() => {
          setHasNewMsg(false);
        }, 100);
        setChatMsg('');
        setShowSend(false);
        props.setNewReplyTo(null);
        setPreviewSrc(null);
        setFile(undefined);
      });
    }
  };

  // function stateMan(obj: any): any {
  //   const o = props.stateMan(obj);
  //   return o;
  // }

  const listName = getListName(listType, list, 0, intl);
  const listnames = listName ? listName.split(/[/|,-]/) : '';
  const listIcons = getListIcons(listType);
  const listIconsDark = getListIcons(listType, IconVariant.dark);

  function renderTab(pro: boolean) {
    return (
      <div
        className={classNames('tab_procon', {
          tab_pro: pro,
          tab_con: !pro,
        })}
        onClick={() => setProTab(pro)}
      >
        {proTab === pro ? listIconsDark[pro ? 0 : 1] : listIcons[pro ? 0 : 1]}
        <div>
          {list.name ? (
            listnames[pro ? 0 : 1]
          ) : (
            <FormattedMessage id={`LISTS.PROCON_${pro ? 1 : 2}`} />
          )}
        </div>
      </div>
    );
  }
  return (
    <div
      className={classNames(
        `tail_${listType.toLowerCase()}`,
        listIs(listType).ProCon
          ? {
              pro: proTab,
              con: !proTab,
            }
          : undefined,
      )}
    >
      {listIs(listType).ProCon ? (
        <div className="tail_tabs">
          {renderTab(true)}
          {renderTab(false)}
        </div>
      ) : null}
      <FileUpload
        types={restrictedImageFileTypes}
        currentSrc={null}
        previewSrc={previewSrc}
        defaultImg={null}
        setPreviewSrc={setPreviewSrc}
        withTrigger={true}
        setFile={setFile}
        onDelete={() => {}}
        className={'tail_container'}
        renderTrigger={(trigger) => (uploadTrigger.current = trigger)}
      >
        <div className="msg_tail">
          <DropMenu right={true} up={true}>
            <DropMenu.Trigger>
              <div className="msg_input_plus" />
            </DropMenu.Trigger>
            <DropMenu.Items>
              <div onClick={uploadTrigger.current}>
                <Icon
                  symbol={IconSymbol.picture}
                  className="msg_input_option"
                />
                <div>
                  <FormattedMessage id="DOCUMENTS.UPLOAD.IMAGE" />
                </div>
              </div>
              {IS_DEV ? (
                <>
                  <div>
                    <Icon
                      symbol={IconSymbol.video}
                      className="msg_input_option"
                    />
                    <div>
                      <FormattedMessage id="DOCUMENTS.UPLOAD.VIDEO" />
                    </div>
                  </div>
                  <div>
                    <Icon
                      symbol={IconSymbol.audio}
                      className="msg_input_option"
                    />
                    <div>
                      <FormattedMessage id="DOCUMENTS.UPLOAD.AUDIO" />
                    </div>
                  </div>
                </>
              ) : null}
            </DropMenu.Items>
          </DropMenu>
          <div className="msg_input">
            {props.newReplyTo && (
              <div className="msg_replyto">
                <Author message={props.newReplyTo as IMessage} />
                {(props.newReplyTo as IMessage).content}
                <Icon
                  className="close_btn"
                  symbol={IconSymbol.close}
                  hoverVariant={IconVariant.dark}
                  onClick={() => props.setNewReplyTo(null)}
                />
              </div>
            )}
            <TextAreaInput
              onChange={handleMsgChange}
              onSubmit={submitMessage}
              placeholder={intl.formatMessage({ id: 'CHAT.NEW_MSG' })}
              value={chatMsg}
              escapeClears={false}
              emojiesBtn={true}
            />
          </div>
          {showSend || !!file ? (
            <div className="msg_input_send" onClick={submitMessage} />
          ) : null}
        </div>
      </FileUpload>
    </div>
  );
}

const ListTail = connect(mapUserStateToProps)(UnconnectedListTail);
