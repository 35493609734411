import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';

import { prepareForBackend, toArrayOfIdObject } from 'helpers/objects';

export type IMessage = API['Message']['read'];
export type IMessageCreate = API['Message']['create'] & {
  filesAdd?: { id: number }[];
};
export type IMessageCreateReply = Omit<IMessageCreate, 'replyTo'> & {
  replyTo: IMessage | number | null;
};
function prepareForCreate(message: IMessageCreateReply): IMessageCreate {
  const filesAdd = message.filesAdd;
  const create = prepareForBackend(message, ['files']) as IMessageCreate;
  if (filesAdd != null && filesAdd.length > 0) {
    create.filesAdd = toArrayOfIdObject(filesAdd);
  }
  return create;
}

type IMessageUpdate = API['Message']['update'] & {
  likesOverwrite?: { id: number }[];
  repliesOverwrite?: { id: number }[];
  filesOverwrite?: { id: number }[];
};
function prepareForUpdate(message: IMessage): IMessageUpdate {
  const updated = prepareForBackend(message, [
    'id',
    'author',
    'likes',
    'replies',
    'files',
    'listItem',
  ]) as IMessageUpdate;
  updated.likesOverwrite = toArrayOfIdObject(message.likes);
  updated.repliesOverwrite = toArrayOfIdObject(message.replies);
  updated.filesOverwrite = toArrayOfIdObject(message.files);
  return updated;
}

export const postMessage = async (data: IMessageCreateReply) => {
  try {
    const response = await api.post('Message', prepareForCreate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchMessage = async (data: IMessage) => {
  try {
    const response = await api.patch(
      'Message/' + data.id,
      prepareForUpdate(data),
    );
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const deleteMessage = async (id: number) => {
  try {
    const response = await api.delete('Message/' + id);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
