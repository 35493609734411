import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Status } from '@thuas/pd-schemas/built/generated/api-types';
import {
  arraySomeById,
  deepSearchKey,
  findIndexById,
} from 'app/AsyncAPI/helpers';
import { RootState } from 'app/store';
import { postBlockData } from 'features/block/blockAPI';
import { BlockType, emptyBlock } from 'features/block/blockSlice';
import { IChatBlockCreate, postChatBlock } from 'features/block/chatBlockAPI';
import {
  IDialogue,
  deleteDialogueData,
  patchDialogueData,
  postDialogueData,
} from 'features/dialogue/dialogueAPI';
import { StateMan } from 'features/dialogue/dialogueSlice';
import { deleteFile, postFile } from 'features/files/fileAPI';
import { FolderLocation, getFolderName } from 'features/files/fileSlice';
import { postPhaseData } from 'features/phase/phaseAPI';
import { emptyPhase } from 'features/phase/phaseSlice';
import { IUser } from 'features/user/userAPI';
import { generateUuidCode } from 'helpers/helpers';
import {
  idOf,
  pickPrimitiveProps,
  pickProps,
  prepareForBackend,
  updateObject,
} from 'helpers/objects';
import { IntlShape } from 'react-intl';
import {
  IProject,
  IProjectCreate,
  deleteProjectData,
  fetchProjects,
  patchProjectData,
  postProjectData,
} from './projectsAPI';

export interface IProjectsState {
  projects: IProject[];
  status: 'idle' | 'loading' | 'failed';
  errors: any;
}

export type ProjectsStateProps = {
  projects: IProject[];
};

export function mapProjectsStateToProps(state: RootState): ProjectsStateProps {
  return {
    projects: state.projects.projects,
  };
}

const initialState: IProjectsState = {
  projects: [],
  status: 'idle',
  errors: '',
};

export const emptyProject: IProject = {
  title: '',
  subtitle: '',
  description: '',
  status: Status.draft,
  dialogues: [],
  files: [],
  invitees: [],
  subscribers: [],
  moderators: [],
  userFeedbacks: [],
  registrationCode: generateUuidCode(),
};

export type Subscriptions = {
  dialogue: IDialogue;
  subscribers?: IUser[];
  moderators?: IUser[];
};

export const fetchProjectsAsync = createAsyncThunk(
  'projects/fetchProjects',
  async () => {
    return { response: await fetchProjects() };
  },
);

// There is no reducer handling for this function,
// because we're redirecting to the new dialogue
// after it has been created in CreateProjectForm.
export const postProjectPlusAsync = createAsyncThunk(
  'projects/postProjectPlusData',
  async (
    {
      data,
      regCode,
      dlgData,
      file,
      intl,
    }: {
      data: IProjectCreate;
      regCode: string;
      dlgData: IDialogue;
      file?: File;
      intl: IntlShape;
    },
    thunkAPI,
  ) => {
    try {
      let res = await postProjectData(data);
      if (res.status === 201) {
        const projectId = res.data._id;
        res = await patchProjectData({
          id: projectId,
          registrationCode: regCode,
        });
        if (res.status === 200) {
          dlgData.project = projectId;
          if (file) {
            const fd = await postFile(file, {
              description: 'Dialogue image',
              folder: getFolderName(FolderLocation.Dialogue),
              order: '',
              size: file.size,
              mimeType: file.type,
              uploadTime: new Date(),
            });
            dlgData.background = { id: fd.data._id, uri: fd.data.message };
          }
          res = await postDialogueData(dlgData);
          if (res.status === 201) {
            dlgData.id = res.data._id;
          }
          if (res.status === 201) {
            const dialogueId = res.data._id;
            const phaseData = {
              ...emptyPhase,
              name: intl.formatMessage({ id: 'PHASES.DEFAULT.TITLE' }),
              order: '1',
              description: intl.formatMessage({
                id: 'PHASES.DEFAULT.DESCRIPTION',
              }),
              dialogue: dialogueId,
              blocks: [],
              active: true,
            };
            res = await postPhaseData(phaseData);
            if (res.status === 201) {
              const phaseId = res.data._id;
              const blockData = {
                ...emptyBlock,
                childType: BlockType.Chat,
                name: intl.formatMessage({ id: 'BLOCKS.DEFAULT.TITLE' }),
                order: '1',
                description: intl.formatMessage({
                  id: 'BLOCKS.DEFAULT.DESCRIPTION',
                }),
                phase: phaseId,
              };
              res = await postBlockData(blockData);
              if (res.status === 201) {
                const blid = res.data._id;
                const ccb: IChatBlockCreate = { parent: blid, messages: [] };
                res = await postChatBlock({ ...ccb });
              }
            }
            res.data._id = dialogueId;
          }
        }
      }
      return { response: res };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const postProjectAsync = createAsyncThunk(
  'projects/postProjectData',
  async ({ data }: { data: IProjectCreate }, thunkAPI) => {
    try {
      return { response: await postProjectData(data) };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const patchProjectAsync = createAsyncThunk(
  'projects/patchProjectData',
  async (
    {
      data,
      file,
      project,
    }: {
      data: IProject;
      file?: File;
      project?: IProject;
    },
    thunkAPI,
  ) => {
    try {
      if (file || data.background === null) {
        // we may be deleting the avatar
        // 1. if there was an avatar, it needs to be deleted
        if (project?.background) {
          // 1.a from storage: TODO
          // 1.b from the db
          if (typeof project.background === 'object')
            await deleteFile(project.background.id);
          else await deleteFile(project.background);
        }
      }
      if (file) {
        // we have a new avatar
        // 2. upload the new file
        const fd = await postFile(file, {
          description: 'Dialogue image',
          folder: getFolderName(FolderLocation.Project, undefined, project?.id),
          order: '',
          size: file.size,
          mimeType: file.type,
          uploadTime: new Date(),
        });
        // 3. keep the new file's id and uri
        data.background = { id: fd.data._id, uri: fd.data.message };
      }
      const res = { response: await patchProjectData(data) };
      return res;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const deleteProjectAsync = createAsyncThunk(
  'projects/deleteProject',
  async (project: IProject, thunkAPI) => {
    try {
      const res = await deleteProjectData(project.id as number);
      return res;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const patchSubscriptionsAsync = createAsyncThunk(
  'projects/patchSubscriptions',
  async (
    { subscriptions }: { subscriptions: Map<number, Subscriptions> },
    thunkAPI,
  ) => {
    try {
      subscriptions.forEach((subscr) => {
        patchDialogueData({
          ...subscr.dialogue,
          subscribers: subscr.subscribers,
          moderators: subscr.moderators,
        });
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const postPatchAndDeleteDialogues = createAsyncThunk(
  'projects/postPatchAndDeleteDialogues',
  async (
    {
      dialogues,
      deletedDialogueIds,
      stateMan,
    }: {
      dialogues: IDialogue[];
      deletedDialogueIds: number[];
      stateMan: StateMan;
    },
    thunkAPI,
  ) => {
    try {
      const newDialogues = dialogues.filter(
        (d) => d.id !== undefined && d.id < 0,
      );
      const oldDialogues = dialogues.filter(
        (d) =>
          d.id !== undefined && d.id >= 0 && !deletedDialogueIds.includes(d.id),
      );
      let res;
      let promises = deletedDialogueIds.map(async (id) => {
        if (id < 0) return true;
        res = await deleteDialogueData(id);
        if (res.status >= 300)
          throw new Error('Error removing dialogue from database');
        return res;
      });
      await Promise.all(promises);
      promises = newDialogues.map(async (d: IDialogue, i) => {
        // first post the dialogue, but without the phases still
        res = await postDialogueData({ ...d, phases: [] });
        if (res.status >= 300)
          throw new Error('Error saving new dialogue to database');
        newDialogues[i].id = res.data._id;
        return res;
      });
      await Promise.all(promises);
      promises = oldDialogues.map(async (d) => {
        const dataPicked = prepareForBackend(
          pickProps(d, ['id', 'title', 'order', 'startedAt', 'closedAt']),
        );
        res = await patchDialogueData(dataPicked);
        if (res.status >= 300)
          throw new Error('Error saving new dialogue order to database');
      });
      await Promise.all(promises);
      return res;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const patchDialogueOrder = createAsyncThunk(
  'projects/patchDialogueOrder',
  async (
    {
      data,
    }: {
      data: IDialogue;
    },
    thunkAPI,
  ) => {
    try {
      const dataPicked = prepareForBackend(pickProps(data, ['id', 'order']));
      const response = await patchDialogueData(dataPicked);
      console.log('response: ', response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const asyncApiProjectsNew = createAsyncThunk(
  '/projects/asyncApiProjectsNew',
  async ({ data }: { data: any }) => {
    return data;
  },
);
export const asyncApiProjectsUpdated = createAsyncThunk(
  '/projects/asyncApiProjectsUpdated',
  async ({ data }: { data: any }) => {
    return data;
  },
);
export const asyncApiProjectsRemoved = createAsyncThunk(
  '/projects/asyncApiProjectsRemoved',
  async ({ data }: { data: any }) => {
    return data;
  },
);

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    resetProjects: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProjectsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.projects = action.payload.response.data;
      })
      .addCase(fetchProjectsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(postProjectAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postProjectAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        const id = action.payload.response.data._id;
        state.projects.push({ ...action.meta.arg.data, id } as IProject);
      })
      .addCase(postProjectAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(patchProjectAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchProjectAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        let pr;
        if (state.hasOwnProperty('projects')) {
          const projs = state.projects;
          const id = action.meta.arg.data.id;
          pr = projs.find((p: any) => p.id === id);
          if (pr)
            Object.assign(pr, {
              ...pr,
              ...pickPrimitiveProps(action.meta.arg.data),
              subscribers: action.meta.arg.data.subscribers,
              moderators: action.meta.arg.data.moderators,
              background: action.meta.arg.data.background || pr.background,
            });
        }
      })
      .addCase(patchProjectAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(postPatchAndDeleteDialogues.pending, (state, action) => {
        state.status = 'loading';
        state.errors = action.payload;
      })
      .addCase(postPatchAndDeleteDialogues.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(postPatchAndDeleteDialogues.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(patchDialogueOrder.pending, (state, action) => {
        state.status = 'loading';
        state.errors = action.payload;
      })
      .addCase(patchDialogueOrder.fulfilled, (state, action) => {
        state.status = 'idle';
        const project = state.projects.find((p) =>
          p.dialogues?.some(
            (d: IDialogue) => idOf(d) === action.meta.arg.data.id,
          ),
        );
        if (project) {
          const dlg = project.dialogues?.find(
            (m: any) => m.id === action.meta.arg.data.id,
          );
          if (dlg) Object.assign(dlg, action.meta.arg.data);
        }
      })
      .addCase(patchDialogueOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })

      .addCase(asyncApiProjectsNew.fulfilled, (state, action) => {
        const payload = action.meta.arg.data;
        if (!arraySomeById(state.projects, payload.id)) {
          state.projects.push({ ...payload });
        }
      })
      .addCase(asyncApiProjectsUpdated.fulfilled, (state, action) => {
        const payload = action.meta.arg.data;
        const projectIndex = findIndexById(state.projects, payload.id);

        const rootUpdated = payload.updated;
        if (!rootUpdated) {
          console.log('rootUpdated NOT DEFINED', payload);
          return;
        }

        if (projectIndex !== -1) {
          const stateProject = state.projects[projectIndex];

          if (deepSearchKey(rootUpdated, 'dialogues')) {
            if (!stateProject.dialogues) stateProject.dialogues = [];
            const payloadDialogues = rootUpdated.dialogues;

            if (payloadDialogues) {
              if (payloadDialogues.new) {
                for (const newDialogue of payloadDialogues.new) {
                  if (!arraySomeById(stateProject.dialogues, newDialogue.id)) {
                    stateProject.dialogues.push(newDialogue);
                  }
                }
              }

              if (payloadDialogues.updated) {
                for (const updatedDialogue of payloadDialogues.updated) {
                  const dialogueIndex = findIndexById(
                    stateProject.dialogues,
                    updatedDialogue.id,
                  );

                  stateProject.dialogues[dialogueIndex!] = updateObject(
                    stateProject.dialogues[dialogueIndex!],
                    updatedDialogue.updated,
                  );
                }
              }

              if (payloadDialogues.removed) {
                for (const deletedDialogue of payloadDialogues.removed) {
                  const dialogueIndex = findIndexById(
                    stateProject.dialogues,
                    deletedDialogue,
                  );

                  if (dialogueIndex !== -1) {
                    stateProject.dialogues.splice(dialogueIndex!, 1);
                  }
                }
              }
            }
          } else if (deepSearchKey(rootUpdated, 'subscribers')) {
            if (!stateProject.subscribers) stateProject.subscribers = [];
            if (!stateProject.moderators) stateProject.moderators = [];
            const payloadSubscribers = rootUpdated.subscribers;

            if (payloadSubscribers) {
              if (payloadSubscribers.new) {
                for (const newSubscriber of payloadSubscribers.new) {
                  if (
                    !arraySomeById(stateProject.subscribers, newSubscriber.id)
                  ) {
                    stateProject.subscribers.push(newSubscriber);
                  }
                }
              }

              if (payloadSubscribers.removed) {
                for (const deletedSubscriber of payloadSubscribers.removed) {
                  const subscriberIndex = findIndexById(
                    stateProject.subscribers,
                    deletedSubscriber,
                  );

                  if (subscriberIndex !== -1) {
                    stateProject.subscribers.splice(subscriberIndex, 1);
                  }
                }
              }
            }
          } else if (deepSearchKey(rootUpdated, 'moderators')) {
            if (!stateProject.subscribers) stateProject.subscribers = [];
            if (!stateProject.moderators) stateProject.moderators = [];
            const payloadModerators = rootUpdated.moderators;

            if (payloadModerators) {
              if (payloadModerators.new) {
                for (const newModerator of payloadModerators.new) {
                  if (
                    !arraySomeById(stateProject.moderators, newModerator.id)
                  ) {
                    stateProject.moderators.push(newModerator);
                  }
                }
              }

              if (payloadModerators.removed) {
                for (const deletedModerator of payloadModerators.removed) {
                  const moderatorIndex = findIndexById(
                    stateProject.moderators,
                    deletedModerator,
                  );

                  if (moderatorIndex !== -1) {
                    stateProject.moderators.splice(moderatorIndex, 1);
                  }
                }
              }
            }
          } else {
            state.projects[projectIndex] = updateObject(
              state.projects[projectIndex],
              rootUpdated,
            );
          }
        }
      })
      .addCase(asyncApiProjectsRemoved.fulfilled, (state, action) => {
        const payload = action.meta.arg.data;
        const index = findIndexById(state.projects, payload);
        if (index !== -1) {
          state.projects.splice(index, 1);
        }
      });
  },
});

export const getProjects = (state: RootState) => state.projects;

export const { resetProjects } = projectsSlice.actions;

export default projectsSlice.reducer;
