import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import {
  arraySomeById,
  deepSearchKey,
  deepSearchKeyType,
  findIndexById,
} from 'app/AsyncAPI/helpers';
import { IDialogueState, StateMan } from 'features/dialogue/dialogueSlice';
import { updateObject, updateObjectDirectly } from 'helpers/objects';
import { IList, deleteList, patchList } from './listAPI';

export const patchListAsync = createAsyncThunk(
  'list/patchList',
  async (
    {
      data,
      stateMan,
    }: {
      stateMan: StateMan;
      data: any;
    },
    thunkAPI,
  ) => {
    try {
      return { response: await patchList(data), stateMan: stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const deleteListAsync = createAsyncThunk(
  'list/deleteList',
  async (
    {
      id,
      stateMan,
    }: {
      id: number;
      stateMan: StateMan;
    },
    thunkAPI,
  ) => {
    try {
      // ToDo delete List items inside a list before deleting a list
      return { response: await deleteList(id), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

// export const postListAsync = createAsyncThunk(
//   'list/postList',
//   async (
//     {
//       stateMan,
//       data,
//     }: {
//       stateMan: StateMan;
//       data: IList;
//     },
//     thunkAPI
//   ) => {
//     try {
//       return {
//         response: await postList(data),
//         stateMan: stateMan,
//       };
//     } catch (error: any) {
//       return thunkAPI.rejectWithValue({ response: error });
//     }
//   }
// );

export const asyncApiListNew = createAsyncThunk(
  '/list/asyncApiListNew',
  async ({ data, stateMan }: { data: any; stateMan: StateMan }) => {
    return { data, stateMan };
  },
);
export const asyncApiListUpdated = createAsyncThunk(
  '/list/asyncApiListUpdated',
  async ({ data, stateMan }: { data: any; stateMan: StateMan }) => {
    return { data, stateMan };
  },
);
export const asyncApiListRemoved = createAsyncThunk(
  '/list/asyncApiListRemoved',
  async ({ data, stateMan }: { data: any; stateMan: StateMan }) => {
    return { data, stateMan };
  },
);

export function addListCases(builder: ActionReducerMapBuilder<IDialogueState>) {
  builder
    .addCase(patchListAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(patchListAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const list = stateObj.hasOwnProperty('listItems') ? stateObj : null;
      // const list = findObjectInState('list', stateObj, action.meta.arg.data.id);
      if (list) Object.assign(list, action.meta.arg.data);
    })
    .addCase(patchListAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(deleteListAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(deleteListAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const lists: IList[] =
        action.meta.arg.stateMan(state).childListBlock.lists;
      const listId = action.meta.arg.id;
      lists.splice(
        lists.findIndex((list) => list.id === listId),
        1,
      );
    })
    .addCase(deleteListAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(asyncApiListNew.fulfilled, (state, action) => {})
    .addCase(asyncApiListUpdated.fulfilled, (state, action) => {
      const payload = action.meta.arg.data;

      const stateObj = action.meta.arg.stateMan(state);
      const lists = stateObj.lists;

      const rootUpdated = payload.updated;

      if (deepSearchKey(rootUpdated, 'lists')) {
        const payloadLists = rootUpdated.lists;

        const isListItems = deepSearchKeyType(
          payloadLists,
          'listItems',
          'array',
          true,
        );

        const isLikes = deepSearchKeyType(payloadLists, 'likes', 'array', true);

        if (!isListItems && !isLikes && deepSearchKey(payloadLists, 'new')) {
          for (const newList of payloadLists.new) {
            if (!arraySomeById(lists, newList.id)) {
              lists.push(newList);
            }
          }
        }

        if (deepSearchKey(payloadLists, 'updated')) {
          for (const updatedList of payloadLists.updated) {
            const listIndex = findIndexById(lists, updatedList.id);

            const stateList = lists[listIndex];
            const stateListItems = stateList.listItems;

            if (isListItems) {
              const updatedListItems = updatedList.updated.listItems;

              if (
                deepSearchKey(updatedListItems, 'new') &&
                updatedListItems.new != null
              ) {
                for (const listItem of updatedListItems.new) {
                  if (!arraySomeById(stateListItems, listItem.id)) {
                    stateListItems.push(listItem);
                  }
                }
              }

              if (deepSearchKey(updatedListItems, 'updated')) {
                for (const listItem of updatedListItems.updated) {
                  const listItemIndex = findIndexById(
                    stateListItems,
                    listItem.id,
                  );

                  const stateListItem = stateListItems[listItemIndex];

                  if (isLikes) {
                    const updatedLikes = listItem.updated.message.updated.likes;

                    if (deepSearchKey(updatedLikes, 'new')) {
                      for (const like of updatedLikes.new) {
                        if (
                          !arraySomeById(stateListItem.message.likes, like.id)
                        ) {
                          stateListItem.message.likes.push(like);
                        }
                      }
                    }
                    if (deepSearchKey(updatedLikes, 'removed')) {
                      for (const like of updatedLikes.removed) {
                        const likeIndex = findIndexById(
                          stateListItem.message.likes,
                          like,
                        );

                        if (likeIndex !== -1) {
                          stateListItem.message.likes.splice(likeIndex, 1);
                        }
                      }
                    }
                  } else {
                    const updatedMessage = listItem.updated.message;

                    if (deepSearchKey(updatedMessage, 'updated')) {
                      if (updatedMessage.updated.replies != null) {
                        const payloadReplies = updatedMessage.updated.replies;

                        if (payloadReplies.new != null) {
                          for (const reply of payloadReplies.new) {
                            if (
                              !arraySomeById(
                                stateListItem.message.replies,
                                reply.id,
                              )
                            ) {
                              stateListItem.message.replies.push(reply);
                            }
                          }
                        }

                        if (payloadReplies.updated != null) {
                          for (const reply of payloadReplies.updated) {
                            const replyIndex = findIndexById(
                              stateListItem.message.replies,
                              reply.id,
                            );

                            if (replyIndex !== -1) {
                              stateListItem.message.replies[replyIndex] =
                                updateObject(
                                  stateListItem.message.replies[replyIndex],
                                  reply.updated,
                                );
                            }
                          }
                        }

                        if (payloadReplies.removed != null) {
                          for (const reply of payloadReplies.removed) {
                            const replyIndex = findIndexById(
                              stateListItem.message.replies,
                              reply,
                            );

                            if (replyIndex !== -1) {
                              stateListItem.message.replies.splice(
                                replyIndex,
                                1,
                              );
                            }
                          }
                        }
                      } else {
                        stateListItem.message = updateObject(
                          stateListItem.message,
                          updatedMessage.updated,
                        );
                      }
                    }

                    updateObjectDirectly(stateListItem, listItem.updated);
                  }
                }
              }

              if (
                deepSearchKey(updatedListItems, 'removed') &&
                updatedListItems.removed != null
              ) {
                for (const listItem of updatedListItems.removed) {
                  const listItemIndex = findIndexById(stateListItems, listItem);

                  if (listItemIndex !== -1) {
                    stateListItems.splice(listItemIndex, 1);
                  }
                }
              }
            } else {
              updateObjectDirectly(stateList, updatedList.updated);
            }
          }
        }

        if (
          !isListItems &&
          !isLikes &&
          deepSearchKey(payloadLists, 'removed')
        ) {
          for (const deletedList of payloadLists.removed) {
            const listIndex = findIndexById(lists, deletedList);

            if (listIndex !== -1) {
              lists.splice(listIndex, 1);
            }
          }
        }
      }
    })
    .addCase(asyncApiListRemoved.fulfilled, (state, action) => {});
}
