import api from 'helpers/api';

export const getWooListItemAndRelatedData = async (
  id: number,
  regcode: string,
) => {
  try {
    const response = await api.get(`woo/ListItem/?id=${id}&regcode=${regcode}`);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const getWooLastOrder = async (blockId: number, regcode: string) => {
  try {
    const response = await api.get(
      `woo/LastOrder/?id=${blockId}&regcode=${regcode}`,
    );
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const getWooFileData = async (id: number, regcode: string) => {
  try {
    const response = await api.get(`woo/File/?id=${id}&regcode=${regcode}`);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const validateFile = async (id: number, regcode: string) => {
  try {
    const response = await api.get(
      `woo/ValidateFile/?id=${id}&regcode=${regcode}`,
    );
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
