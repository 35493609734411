import StaticPage from 'components/static/StaticPage';
import { SLUGS } from 'helpers/consts';
import { FormEvent, SyntheticEvent, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Params, useLoaderData, useLocation, useNavigate } from 'react-router';

import { useAppDispatch } from 'app/hooks';
import {
  doFormMsg,
  FormMessage,
  FormMsg,
  NoFormMsg,
} from 'components/forms/FormMessage';
import { ImageViewLink } from 'components/messages/ImageViewLink';
import Message from 'components/messages/Message';
import { navigateIn } from 'components/navigation/Navigation';
import {
  AppSettingsStateProps,
  mapAppSettingsStateToProps,
} from 'features/admin/appSettingsSlice';
import { getBlockAndRelatedData, IBlock } from 'features/block/blockAPI';
import { IFile } from 'features/files/fileAPI';
import { IList } from 'features/list/listAPI';
import { IListItem } from 'features/list/listItemAPI';
import {
  getWooFileData,
  getWooLastOrder,
  getWooListItemAndRelatedData,
  validateFile,
} from 'features/list/listItemAPI-WOO';
import { postListItemAsync } from 'features/list/listItemSlice';
import { IMessage } from 'features/message/messageAPI';
import {
  ISignupGuestData,
  logout,
  mapUserStateToProps,
  signupGuestAsync,
  UserStateProps,
} from 'features/user/userSlice';
import api from 'helpers/api';
import { getGaugeStyle } from 'helpers/helpers';
import { AnyOrderObject } from 'helpers/objects';
import { useRandomName } from 'helpers/randomName';
import { getNextOrderAfterLast, sortByOrder } from 'helpers/sorting';

import './Woo.scss';

type WooFormProps = {
  result?: boolean;
};
type WooData = {
  name: string;
  email: string;
  goal: string;
  means: string;
};
const a = {
  extension: 'WOO',
  data: {
    totalTiles: 151,
    maxOccupation: 165,
    totalBuild: 108,
    percentageBuild: 0,
    tileAmounts: {
      clearTiles: 67,
      level1LightTiles: 11,
      level2LightTiles: 4,
      buildHouses: 0,
      buildPlayground: 31,
      buildGroen: 22,
      buildWater: 10,
      buildHoreca: 5,
      buildFietspad: 32,
      buildSport: 8,
    },
    tilePercentages: {
      vergroeningsPercentage: 36.4860725402832,
      ontspanningsPercentage: 60.75570297241211,
      veiligheidsPercentage: 88.38383483886719,
    },
  },
};

type WooScore = {
  vergroeningsPercentage: number;
  ontspanningsPercentage: number;
  veiligheidsPercentage: number;
};

export function getWooScore(input: string): WooScore {
  if (input) {
    const data = JSON.parse(input);
    if (data && data.data?.tilePercentages)
      return {
        vergroeningsPercentage:
          data.data.tilePercentages.vergroeningsPercentage,
        ontspanningsPercentage:
          data.data.tilePercentages.ontspanningsPercentage,
        veiligheidsPercentage: data.data.tilePercentages.veiligheidsPercentage,
      };
  }
  return {
    vergroeningsPercentage: 0,
    ontspanningsPercentage: 0,
    veiligheidsPercentage: 0,
  };
}

function WooScoreItem(props: {
  title: string;
  value: number;
  initem?: boolean;
}) {
  const { title, value, initem } = props;
  return (
    <div className="woo_score_item">
      <div className="woo_score_label">
        <FormattedMessage id={`WOO.FORM.LABEL_${title}`} />:
      </div>
      <div className="woo_bar">
        <div className="woo_gauge" style={getGaugeStyle(value)} />
        <div className={initem ? 'woo_stars' : 'woo_stars'} />
      </div>
      <div className="woo_value">{Math.round(value / 2) / 10}</div>
    </div>
  );
}

export function WooScoreView(props: { score: WooScore; initem?: boolean }) {
  const { score, initem } = props;
  return (
    <div className={`woo_score${initem ? ' woo_score_initem' : ''}`}>
      <div className="woo_score_title">
        <FormattedMessage
          id={initem ? 'WOO.FORM.SCORE_ITEM' : 'WOO.FORM.SCORE'}
        />
      </div>
      <WooScoreItem
        title="GREEN"
        value={score.vergroeningsPercentage}
        initem={initem}
      />
      <WooScoreItem
        title="RELAX"
        value={score.ontspanningsPercentage}
        initem={initem}
      />
      <WooScoreItem
        title="SAFETY"
        value={score.veiligheidsPercentage}
        initem={initem}
      />
    </div>
  );
}

function UnconnectedWooForm(
  props: WooFormProps & UserStateProps & AppSettingsStateProps,
) {
  const { userId, user, result } = props;
  const config = props.settings?.config;
  const [data, setData] = useState<WooData>({
    name: '',
    email: '',
    goal: '',
    means: '',
  });
  const [validated, setValidated] = useState(false);
  const params = useLoaderData() as Params;
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(0);
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  const getRandomName = useRandomName();
  const [block, setBlock] = useState<IBlock | null>(null);
  const [list, setList] = useState<IList | null>(null);
  const [lastOrder, setLastOrder] = useState<string | null>(null);
  const [listItemId, setListItemId] = useState(0);
  const [listItem, setListItem] = useState<IListItem | null>(null);
  const [file, setFile] = useState<IFile | null>(null);
  const [score, setScore] = useState<WooScore | null>(null);

  useEffect(() => {
    if (result && params.listitemid && params.regcode) {
      setStep(3);
      setListItemId(parseInt(params.listitemid) || 0);
    } else {
      const fetchData = async () => {
        if (params.regcode) {
          const fid = params.fid ? parseInt(params.fid) || 0 : 0;
          if (fid) {
            getWooFileData(fid, params.regcode).then(async (response) => {
              if (response.status === 200) {
                setFile(response.data);
                setScore(getWooScore(response.data.description));
              }
            });
          }
        }
      };
      // Check hier voor File, (validateFile).
      // 200 - null is goed, continue
      // 403 - parseInt(listItem ) is fout, redirect
      // Redirect naar listitem met listitemid, id komt vanuit backend
      if (params.regcode) {
        const fid = params.fid ? parseInt(params.fid) || 0 : 0;
        validateFile(fid, params.regcode)
          .then(async (response) => {
            // Continue flow, File exist and was not used before
            fetchData();
          })
          .catch((response) => {
            if (response.status === 403) {
              navigateIn(`/m/woo/r/${params.regcode}/${response.data}`, 0);
            } else {
              // 500 error, continue flow, File does not exist or is not WOO
            }
          });
      }
    }
    const handlePopState = (e: PopStateEvent) => {
      e.preventDefault();
      prevStep();
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // get the last order number from the list
      if (params.regcode) {
        const res = await getWooLastOrder(config?.woo?.list, params.regcode);
        const order = res.data;
        setLastOrder(order);
      }
    };
    fetchData();
  }, [config?.woo?.list]);

  useEffect(() => {
    navigate(location.pathname, { replace: true });
  }, [step, navigate, location.pathname]);

  useEffect(() => {
    if (listItemId && params.regcode) {
      getWooListItemAndRelatedData(listItemId, params.regcode).then(
        (response) => {
          if (response.status === 200) {
            setListItem(response.data);
            setScore(getWooScore(response.data.message.files[0].description));
          }
        },
      );
    }
  }, [listItemId]);

  function nextStep() {
    setStep((prev) => prev + 1);
    window.history.pushState(null, '', location.pathname);
  }

  function prevStep() {
    setStep((prev) => {
      if (prev >= 2) return prev;
      return Math.max(0, prev - 1);
    });
    window.history.replaceState(null, '', location.pathname);
  }

  const handleChangeData = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    setData({
      ...data,
      [target.name]: target.value,
    });
  };

  async function handleSubmit(e: MouseEvent | FormEvent): Promise<void> {
    return new Promise(async (resolve, reject) => {
      e.preventDefault();
      const form: HTMLFormElement | null =
        (e.currentTarget as HTMLButtonElement).form ||
        (e.currentTarget as HTMLFormElement);
      if (form && form.checkValidity() === false) {
        setValidated(true);
        e.stopPropagation();
        return false;
      } else {
        switch (step) {
          case 0:
            // register and log in as guest
            if (user) dispatch(logout()); // log out user if already logged in
            const guestData: ISignupGuestData = {
              username:
                data.name.trim().length > 0 ? data.name : getRandomName(),
              guestAccessCode: params.regcode ?? '',
            };
            dispatch(signupGuestAsync(guestData)).then(
              (response: { payload: any }) => {
                switch (response.payload?.response.status) {
                  case 200:
                  case 201:
                    nextStep();
                    resolve();
                    break;
                  case 406:
                    doFormMsg(
                      {
                        message: intl.formatMessage({
                          id: 'WOO.CONFIG.REGCODE_ERROR',
                        }),
                        success: false,
                      },
                      setFormMsg,
                    );
                    reject();
                    break;
                  default:
                    doFormMsg(
                      {
                        message: intl.formatMessage({
                          id: 'USERS.SIGNUP.FAILED',
                        }),
                        success: false,
                      },
                      setFormMsg,
                    );
                    reject();
                    break;
                }
              },
            );
            break;
          case 1:
            // get the order number from the list
            const res = await getBlockAndRelatedData(config?.woo?.list);
            const block: IBlock = res.data;
            const lists = sortByOrder(
              (block.childListBlock?.lists as AnyOrderObject[]) ?? [],
            );
            const list = lists.length > 0 ? lists[lists.length - 1] : null;
            if ((lastOrder || lastOrder === '') && list) {
              const order = getNextOrderAfterLast(lastOrder, false);
              // post list item with message
              const content =
                `***${intl.formatMessage({ id: 'WOO.GOAL' })}***: ` +
                data.goal +
                `  \n***${intl.formatMessage({ id: 'WOO.MEANS' })}***: ` +
                data.means;
              const fid = params.fid ? parseInt(params.fid) || 0 : 0;
              setValidated(false);
              dispatch(
                postListItemAsync({
                  data: {
                    content: content,
                    edited: false,
                    time: new Date(),
                    author: userId,
                    replyTo: null,
                    checked: false,
                    color: 'none',
                    likes: [],
                    replies: [],
                    filesAdd: fid ? [{ id: fid }] : undefined,
                    files: [],
                  },
                  alt: 'WOO',
                  listId: list.id!,
                  order: order,
                  pinned: true,
                  author: user,
                  stateMan: null,
                }),
              ).then(async (response: any) => {
                switch (response.payload?.response.status) {
                  case 201:
                    const localListItemId = response.payload.response.data._id;
                    setListItemId(localListItemId);
                    nextStep();
                    // Send mail
                    if (data.email) {
                      const response = await api.post('woo/email', {
                        name: data.name ? data.name : null,
                        email: data.email,
                        locale: user.locale?.toUpperCase(),
                        regcode: params.regcode,
                        itemid: localListItemId,
                      });
                    }
                    resolve();
                    break;
                  default:
                    console.log(
                      response,
                      response?.payload.response.status,
                      response?.payload.response.data?.message,
                    );
                }
                return true;
              });
            } else {
              doFormMsg(
                {
                  message: intl.formatMessage({
                    id: 'WOO.CONFIG.LIST_ERROR',
                  }),
                  success: false,
                },
                setFormMsg,
              );
              reject();
            }
            break;
          default:
            break;
        }
      }
    });
  }

  function renderContent() {
    if (
      params.regcode &&
      (params.fid || params.listitemid) &&
      config?.woo?.enabled
    ) {
      if (params.fid && !file) {
        return (
          <div className="woo_container">
            <h1>
              <FormattedMessage id="WOO.FORM.NOFILE_TITLE" />
            </h1>
            <FormattedMessage id="WOO.FORM.NOFILE" />
          </div>
        );
      }
      switch (step) {
        case 0:
          return (
            <Form noValidate validated={validated} className="woo_form">
              <h1>
                <FormattedMessage id="WOO.FORM.FORM_STEP1" />
              </h1>
              <Form.Label>
                <FormattedMessage id="WOO.FORM.QUESTIONS" />
              </Form.Label>
              <FormMessage {...formMsg} key={formMsg.key} />
              {file ? <ImageViewLink file={file} /> : null}
              <Form.Group controlId="formName">
                <Form.Label>
                  <FormattedMessage id="WOO.FORM.NAME" />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'WOO.FORM.NAME_NONE',
                  })}
                  name="name"
                  value={data.name}
                  onChange={handleChangeData}
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>
                  <FormattedMessage id="WOO.FORM.EMAIL" />
                  <br />
                  <FormattedMessage id="WOO.FORM.EMAIL_2" />
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="example@email.com"
                  name="email"
                  value={data.email}
                  onChange={handleChangeData}
                />
              </Form.Group>
              <Form.Group controlId="formNext">
                <Button variant="primary" onClick={handleSubmit}>
                  <FormattedMessage
                    id="WOO.FORM.NEXT"
                    values={{
                      name: data.name.length > 0 ? data.name : undefined,
                    }}
                  />
                </Button>
              </Form.Group>
            </Form>
          );
        case 1:
          return (
            <Form noValidate validated={validated} className="woo_form">
              <div>
                {!data.name ? (
                  <>
                    <FormattedMessage
                      id="WOO.FORM.NAME_NONE_MSG"
                      values={{
                        name: user.username,
                      }}
                    />
                    {' 😊'}
                  </>
                ) : (
                  <>
                    <FormattedMessage
                      id="WOO.FORM.WELCOME"
                      values={{
                        name: user.username,
                      }}
                    />
                    {'!'}
                  </>
                )}
              </div>
              <h1>
                <FormattedMessage
                  id="WOO.FORM.FORM_STEP2"
                  values={{
                    name: data.name || undefined,
                  }}
                />
              </h1>
              {score ? <WooScoreView score={score} /> : null}
              <FormMessage {...formMsg} key={formMsg.key} />
              <Form.Group controlId="formGoal">
                <Form.Label>
                  <FormattedMessage id="WOO.FORM.GOAL" />
                  {/* <RequiredField /> */}
                </Form.Label>
                <Form.Control
                  required
                  className="woo_textarea"
                  type="text"
                  as="textarea"
                  rows={3}
                  placeholder={intl.formatMessage({
                    id: 'WOO.FORM.GOAL_PLCH',
                  })}
                  name={'goal'}
                  value={data.goal}
                  onChange={handleChangeData}
                  minLength={3}
                />
              </Form.Group>
              <Form.Group controlId="formMeans">
                <Form.Label>
                  <FormattedMessage id="WOO.FORM.MEANS" />
                  {/* <RequiredField /> */}
                </Form.Label>
                <Form.Control
                  required
                  className="woo_textarea"
                  type="text"
                  as="textarea"
                  rows={3}
                  placeholder={intl.formatMessage({
                    id: 'WOO.FORM.MEANS_PLCH',
                  })}
                  name={'means'}
                  value={data.means}
                  onChange={handleChangeData}
                  minLength={3}
                />
              </Form.Group>
              <Form.Group controlId="formSubmit">
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={
                    data.goal.trim().length === 0 ||
                    data.means.trim().length === 0
                  }
                >
                  {intl.formatMessage({ id: 'WOO.FORM.SUBMIT' })}
                </Button>
              </Form.Group>
              {file ? <ImageViewLink file={file} /> : null}
            </Form>
          );
        case 2:
        case 3:
          return (
            <>
              <h1>
                <FormattedMessage id="WOO.FORM.FORM_STEP3" />
              </h1>
              <div className="woo_success">
                <FormattedMessage
                  id={
                    result
                      ? 'WOO.FORM.RESULT'
                      : data.email
                        ? 'WOO.FORM.SUCCESS'
                        : 'WOO.FORM.SUCCESS_ANON'
                  }
                />
                {!result && data.email ? (
                  <>
                    <br />
                    <FormattedMessage id="WOO.FORM.SUCCESS_EMAIL" />
                  </>
                ) : null}
              </div>
              {listItem ? (
                <>
                  <Message
                    className={''}
                    message={listItem.message as IMessage}
                    listItemId={listItem.id}
                    showLikes={false}
                    showColours={false}
                    showAttribution={false}
                    stateMan={() => {}}
                    canPin={false}
                    extraContent={
                      result && score ? (
                        <WooScoreView score={score} initem />
                      ) : null
                    }
                  />
                  {!result && score ? <WooScoreView score={score} /> : null}
                </>
              ) : null}
            </>
          );
        default:
          return null;
      }
    }
  }

  return (
    <div className="woo_container">
      {renderContent() || <StaticPage slug={SLUGS.WOO} />}
    </div>
  );
}

const WooForm = connect()(
  connect(mapUserStateToProps)(
    connect(mapAppSettingsStateToProps)(UnconnectedWooForm),
  ),
);
export default WooForm;
