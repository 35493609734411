import classNames from 'classnames';
import React, {
  ReactNode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { useForms } from 'components/forms/Forms';
import { ModalDialog } from 'components/forms/ModalDialog';
import { IAppSettings } from 'features/admin/appSettingsAPI';
import {
  AppSettingsStateProps,
  emptySettings,
  mapAppSettingsStateToProps,
  patchAppSettingsAsync,
} from 'features/admin/appSettingsSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';

import { SettingsCheckbox } from './AppSettingsForm';
import './ProjectsForm.scss';

function UnconnectedWooSettingsForm(
  props: FormProps & UserStateProps & AppSettingsStateProps,
) {
  const { show, setShow, userIsAdmin, settings } = props;
  const dispatch = useAppDispatch();
  const [data, setData] = useState<IAppSettings>(emptySettings);
  const intl = useIntl();
  const scrollableRef = useRef<HTMLTableSectionElement>(null);
  const listsRef = useRef<HTMLDivElement>();

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  useEffect(() => {
    if (show && settings) {
      setData({
        ...settings,
        config: settings.config ?? {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  if (!userIsAdmin) return null;

  function handleCheckWoo(name: string, checked: boolean) {
    if (data.config)
      setData({
        ...data,
        config: {
          ...data.config,
          woo: {
            ...data.config.woo,
            [name]: !checked,
          },
        },
      });
  }

  function handleChangeWoo(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    if (data.config)
      setData({
        ...data,
        config: {
          ...data.config,
          woo: {
            ...data.config.woo,
            [target.name]: target.value,
          },
        },
      });
  }

  async function handleSubmit(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(patchAppSettingsAsync({ data })).then(
          (response: { payload: any }) => {
            switch (response.payload?.response.status) {
              case 200:
              case 201:
                resolve();
                break;
              case 401:
              default:
                doFormMsg(
                  {
                    message: intl.formatMessage({ id: 'X.FAILED' }),
                    success: false,
                  },
                  setFormMsg,
                );
                reject();
                break;
            }
          },
        );
      } catch (err) {
        doFormMsg(
          {
            message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
            success: false,
          },
          setFormMsg,
        );
        console.log('Login request failed for unclear reason.', err);
        reject();
      }
    });
  }

  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="settings_form appsettings_form"
      title={<FormattedMessage id={'WOO.CONFIG.FORM_TITLE'} />}
      noFooter={false}
      onSubmit={handleSubmit}
      onCancel={() => true}
      editing={true}
      enableEscape={false}
      formMsg={formMsg}
      refScrollable={scrollableRef}
    >
      {/* <div className="header">
        <div className="subheading">
          <FormattedMessage id="WOO.CONFIG.DIALOGUE" />
        </div>
      </div> */}
      <Form.Group as={Row}>
        <Col sm={4} md={4} lg={4} className="rightaligned">
          <SettingsCheckbox
            key={'enabled'}
            name={'enabled'}
            label={intl.formatMessage({
              id: 'WOO.CONFIG.ENABLED',
            })}
            checked={data?.config?.woo?.enabled ?? false}
            onCheck={handleCheckWoo}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={4} md={4} lg={4} className="rightaligned">
          <FormattedMessage id="WOO.CONFIG.DIALOGUE_ID" />:
        </Col>
        <Col sm={4} md={4} lg={4}>
          <Form.Control
            type="number"
            name="dialogue"
            value={data?.config?.woo?.dialogue || 0}
            onChange={handleChangeWoo}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={4} md={4} lg={4} className="rightaligned">
          <FormattedMessage id="WOO.CONFIG.LIST_ID" />:
        </Col>
        <Col sm={4} md={4} lg={4}>
          <Form.Control
            type="number"
            name="list"
            value={data?.config?.woo?.list || 0}
            onChange={handleChangeWoo}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={4} md={4} lg={4} className="rightaligned">
          <FormattedMessage id="WOO.CONFIG.REGCODE" />:
        </Col>
        <Col sm={4} md={4} lg={4}>
          <Form.Control
            type="text"
            name="regcode"
            value={data?.config?.woo?.regcode ?? ''}
            onChange={handleChangeWoo}
          />
        </Col>
      </Form.Group>
    </ModalDialog>
  );
}

const WooSettingsForm = connect()(
  connect(mapUserStateToProps)(
    connect(mapAppSettingsStateToProps)(UnconnectedWooSettingsForm),
  ),
);
export default WooSettingsForm;

export function WooSettingsFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  tag?: 'span' | 'div';
  title?: string;
  onCloseAll?: () => void;
  children?: ReactNode;
}) {
  const { onClick, children, title, tag, className } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.WooSettingsForm.setShow(true);
    onClick && onClick(e);
  }

  const TagName = tag ?? 'div';
  return (
    <TagName
      className={classNames(className)}
      onClick={handleClick}
      title={title}
    >
      {children}
    </TagName>
  );
}
