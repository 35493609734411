import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { useEffect, useState } from 'react';

type MessageContentProps = {
  content: string;
};

export function MessageContent(props: MessageContentProps) {
  const [processedContent, setProcessedContent] = useState<string | undefined>(
    props.content,
  );

  useEffect(() => {
    setProcessedContent(process(props.content ?? ''));

    function process(content: string): string {
      const processed = marked.parse(preprocess(content ?? ''), {
        async: false,
      });
      let sanitized = DOMPurify.sanitize(processed, {
        USE_PROFILES: { html: true },
      });
      return postprocess(sanitized);
    }
    function preprocess(content: string): string {
      return content.replace(/(?<!\n)\n(?!\n)/g, '  \n');
    }
    function postprocess(content: string): string {
      return content.replace(/<a\s+(?![^>]*\btarget=)[^>]*>/g, (match) => {
        return match.replace(/^<a\s*/, '<a target="_blank" ');
      });
    }
  }, [props.content]);

  return <div dangerouslySetInnerHTML={{ __html: processedContent ?? '' }} />;
}
