import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';
import { callGet, Retrieving } from 'helpers/apiTypes';

import { prepareForBackend } from 'helpers/objects';

export type IListItem = API['ListItem']['read'];
export type IListItemCreate = API['ListItem']['create'];
type IListItemUpdate = API['ListItem']['update'];

function prepareForUpdate(listItem: IListItem): IListItemUpdate {
  return prepareForBackend(listItem, ['id']) as IListItemUpdate;
}
function prepareForCreate(
  listItem: IListItem & IListItemCreate,
): IListItemCreate {
  return prepareForBackend(listItem) as IListItemCreate;
}

export const getListItemAndRelatedData = async (id: number) => {
  try {
    const query = {
      '*': true,
      message: {
        '*': true,
        author: {
          '*': true,
          avatar: {
            uri: true,
          },
        },
        likes: {
          '*': true,
          liker: {
            id: true,
            username: true,
          },
        },
        replyTo: {
          id: true,
        },
        replies: {
          '*': true,
          author: {
            '*': true,
            avatar: {
              uri: true,
            },
          },
          replyTo: {
            id: true,
          },
        },
        files: {
          '*': true,
        },
      },
    } as const satisfies Retrieving<'ListItem'>;
    const response = await callGet('ListItem', query, id);
    return response.response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const postListItem = async (data: IListItem & IListItemCreate) => {
  try {
    const response = await api.post('ListItem', prepareForCreate(data));
    // const id = response.data._id;
    // response.data = { ...data, id };
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchListItem = async (data: IListItem) => {
  try {
    const response = await api.patch(
      'ListItem/' + data.id,
      prepareForUpdate(data),
    );
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const deleteListItem = async (id: number) => {
  try {
    const response = await api.delete('ListItem/' + id);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
