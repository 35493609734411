import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

import { RootState, store } from 'app/store';
import { NotInitial } from 'features/admin/appSettingsAPI';
import { BlockType } from 'features/block/blockSlice';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { deleteFile, postFile } from 'features/files/fileAPI';
import { FolderLocation, getFolderName } from 'features/files/fileSlice';
import { IProject } from 'features/projects/projectsAPI';
import { addUiReducers, addUiStateCases } from 'features/uiState/uiStateSlice';
import { LocaleCode } from 'helpers/intl/messages';
import { mergeObjects, omitProps, sameId } from 'helpers/objects';
import { sortBy } from 'helpers/sorting';
import {
  createAuthorName,
  fetchUser,
  IAuthorName,
  ISubscription,
  IUser,
  IUserWithIsGuest,
  loginUser,
  patchUserData,
  postGuestData,
  postUserData,
  register,
  resetPwd,
  Role,
  subscribe,
  userForgot,
  verifyMail,
} from './userAPI';

export interface IUserState {
  user: IUser;
  userId: number | undefined;
  status: 'idle' | 'loading' | 'failed';
  errors: any;
  // userIsModerator: boolean;
  userLoggedIn: boolean;
}

export type UserStateProps = {
  userLoggedIn: boolean;
  user: IUser;
  userId: number;
  userIsGuest: boolean;
  userIsAdmin: boolean;
  userIsManager: boolean;
  userIsModerator(object: IDialogue | IProject): boolean;
  userCanEdit(object: IDialogue | IProject): boolean;
  locale: LocaleCode;
};

export function mapUserStateToProps(state: RootState): UserStateProps {
  const guest: boolean = (state.user.user.email?.length ?? 0) === 0;
  return {
    userLoggedIn: Boolean(Cookies.get('token')),
    user: state.user.user,
    userId: state.user.userId,
    userIsGuest: guest,
    userIsAdmin: state.user.user.roles.includes(Role.admin),
    userIsManager:
      state.user.user.roles.includes(Role.manager) ||
      state.user.user.roles.includes(Role.admin),
    userIsModerator: (object: IDialogue | IProject): boolean => {
      return (
        object.moderators?.some((m) => sameId(m.id, state.user.userId)) || false
      );
    },
    userCanEdit: (object: IDialogue | IProject): boolean => {
      return (
        state.user.user.roles.includes(Role.admin) ||
        state.user.user.roles.includes(Role.manager) ||
        (object.moderators &&
          object.moderators.some((m) => sameId(m.id, state.user.userId)))
      );
    },
    locale: state.intl.locale,
  };
}

export function getAuthors(dialogue?: IDialogue): IAuthorName[] {
  const d: IDialogue = dialogue ?? store.getState().dialogue.dialogue;
  if (!d || !d.subscribers) return [];
  const userMap: Map<number, IUser> = new Map<number, IUser>([]);
  d.subscribers.map((u) => userMap.set(u.id!, u));
  d.phases?.forEach((ph) => {
    ph.blocks?.forEach((bl) => {
      switch (bl.childType) {
        case BlockType.Chat:
          bl.childChatBlock?.messages?.forEach((m) => {
            if (m.author) {
              userMap.set(m.author.id!, m.author);
            }
          });
          break;
        case BlockType.List:
          bl.childListBlock?.lists?.forEach((l) => {
            l.listItems?.forEach((li) => {
              if (li.message?.author)
                userMap.set(li.message?.author.id!, li.message?.author);
            });
          });
      }
    });
  });
  const authors: IAuthorName[] = sortBy(
    Array.from(userMap.values()).map((u: IUser) => {
      return createAuthorName(u);
    }),
    'username', // first sort by username
    'ASC',
    undefined,
    'id', // then by id (first registered gets lowest seq number)
    'ASC',
  ) as IAuthorName[];
  authors.forEach((u: IAuthorName, i, arr) => {
    if (i > 0 && arr[i - 1].username === u.username) {
      arr[i].seq = (arr[i - 1].seq || 1) + 1;
      if (!arr[i - 1].seq) arr[i - 1].seq = 1;
    }
  });
  return authors;
}

export function getAuthorName(user: IUser, authors: IAuthorName[]): string {
  return (
    (authors && authors.length
      ? authors.find((a) => a.id === user.id)?.authorname || user.username
      : user.username) ?? ''
  );
}

export interface IDecodedResponse {
  [key: string]: any;
}

export interface ISignupData {
  username: string;
  email: string;
  password: string;
  registrationCode: string;
}

export interface ISignupGuestData {
  username: string;
  guestAccessCode: string;
}

interface ILoginData {
  email: string;
  token: string;
  password: string;
}
interface IForgotData {
  email: string;
}
interface IVerifyData {
  token: string;
  regcode?: string;
}

export const emptyUser: IUser = {
  subscribedToProjects: [],
  moderatesProjects: [],
  subscribedToDialogues: [],
  moderatesDialogues: [],
  username: '',
  email: '',
  roles: [],
  locale: '',
  avatar: null,
  userFeedbacks: [],
  votes: [],
  messages: [],
  likes: [],
  uploadedFiles: [],
  dialogues: [],
  projects: [],
};

export const initialState: IUserState = {
  user: {
    ...emptyUser,
    id: 0,
    _initial: true,
  },
  userId: undefined,
  // userIsModerator: false,
  status: 'idle',
  errors: '',
  userLoggedIn: false, //Cookies.get('token') ? true : false,
};

export const fetchUserAsync: any = createAsyncThunk(
  'user/fetchUser',
  async (id: number, thunkAPI) => {
    try {
      return { response: await fetchUser(id) };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const loginUserAsync: any = createAsyncThunk(
  'user/loginUser',
  async (data: ILoginData, thunkAPI) => {
    try {
      return { response: await loginUser(data) };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const signupUserAsync = createAsyncThunk(
  'user/postUserData',
  async (data: ISignupData, thunkAPI) => {
    try {
      return { response: await postUserData(data) };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const signupGuestAsync = createAsyncThunk(
  'user/postGuestData',
  async (data: ISignupGuestData, thunkAPI) => {
    try {
      const postRes = await postGuestData(data);
      if (postRes.status === 201) {
        // console.log('res post:', postRes);
        const fetchRes = await thunkAPI.dispatch(
          fetchUserAsync(postRes.data._id),
        );
        // console.log('res fetch:', fetchRes);
        return { response: fetchRes.payload.response };
      } else throw new Error('Error creating guest user');
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const userForgotAsync: any = createAsyncThunk(
  'user/userForgot',
  async (data: IForgotData, thunkAPI) => {
    try {
      return { response: await userForgot(data) };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const resetPwdAsync: any = createAsyncThunk(
  'user/resetPwd',
  async (data: ILoginData, thunkAPI) => {
    try {
      return { response: await resetPwd(data) };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const verifyMailAsync: any = createAsyncThunk(
  'user/verifyMail',
  async (data: IVerifyData, thunkAPI) => {
    try {
      const res = await verifyMail(data);
      return { response: res };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const patchUserAsync = createAsyncThunk(
  'user/patchUserData',
  async (
    {
      data,
      file,
      user,
    }: {
      data: IUserWithIsGuest;
      file?: File;
      user?: IUser;
    },
    thunkAPI,
  ) => {
    if (!data.id) data.id = user?.id;
    try {
      if (file || !data.avatar) {
        // we may be deleting the avatar
        // 1. if there was an avatar, it needs to be deleted
        if (user?.avatar) {
          // 1.a from storage: TODO
          // 1.b from the db
          if (typeof user.avatar === 'object') await deleteFile(user.avatar.id);
          else await deleteFile(user.avatar);
        }
      }
      if (file) {
        // we have a new avatar
        // 2. upload the new file
        const fd = await postFile(file, {
          description: 'Avatar',
          folder: getFolderName(FolderLocation.Avatars),
          order: '',
          size: file.size,
          mimeType: file.type,
        });
        // 3. keep the new file's id and uri
        data.avatar = { id: fd.data._id, uri: fd.data.message };
      }
      // 4. update the user
      return { response: await patchUserData(omitProps(data, ['_initial'])) };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const registerAsync: any = createAsyncThunk(
  'user/regcode',
  async (data: ISubscription, thunkAPI) => {
    try {
      return { response: await register(data) };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const subscribeAsync: any = createAsyncThunk(
  'user/subscribe',
  async (data: ISubscription, thunkAPI) => {
    try {
      return { response: await subscribe(data) };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const unsubscribeAsync: any = createAsyncThunk(
  'user/subscribe',
  async (data: ISubscription, thunkAPI) => {
    try {
      data.subscribe = false;
      return { response: await subscribe(data) };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    // setUserIsModerator: (state, action: PayloadAction<boolean>) => {
    //   state.userIsModerator = action.payload;
    // },
    setUserId: (state, action: PayloadAction<number | undefined>) => {
      state.userId = action.payload;
    },
    logout: (state) => {
      Cookies.remove('token');
      return initialState;
    },
    ...addUiReducers(),
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.userLoggedIn = Boolean(action.payload.response.data.id);
        state.user = NotInitial(action.payload.response.data);
      })
      .addCase(fetchUserAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(loginUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        let token = Cookies.get('token');
        let decodedResponse: IDecodedResponse = token ? jwtDecode(token) : {};
        state.userId = decodedResponse?.data.user.id;
        state.userLoggedIn = token ? true : false;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(signupUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signupUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(signupUserAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(userForgotAsync.pending, (state) => {
        // state.status = 'loading';
      })
      .addCase(userForgotAsync.fulfilled, (state, action) => {
        // state.status = 'idle';
      })
      .addCase(userForgotAsync.rejected, (state, action) => {
        // state.status = 'failed';
        // state.errors = action.payload;
      })
      .addCase(resetPwdAsync.pending, (state) => {
        // state.status = 'loading';
      })
      .addCase(resetPwdAsync.fulfilled, (state, action) => {
        // state.status = 'idle';
      })
      .addCase(resetPwdAsync.rejected, (state, action) => {
        // state.status = 'failed';
        // state.errors = action.payload;
      })
      .addCase(verifyMailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(verifyMailAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(verifyMailAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(patchUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        // Don't just assign the payload.data to the state,
        // use mergeObjects to ignore props that have value undefined.
        // proxyLog('state.user', state.user);
        state.user = mergeObjects(state.user, action.meta.arg.data);
      })
      .addCase(patchUserAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      });
    addUiStateCases(builder);
  },
});

export const userActions = userSlice.actions;
export const {
  setUser,
  setUserId,
  logout,
  // setUserIsModerator
} = userSlice.actions;

export default userSlice.reducer;
