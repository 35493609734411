import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';

import AppSettingsForm from 'components/admin/AppSettingsForm';
import ProjectsForm from 'components/admin/ProjectsForm';
import UserDataForm from 'components/admin/UserDataForm';
import UsersForm from 'components/admin/UsersForm';
import WooSettingsForm from 'components/admin/WooSettingsForm';
import UserForgotForm from 'components/user/UserForgotForm';
import UserLoginForm from 'components/user/UserLoginForm';
import UserProfileForm from 'components/user/UserProfileForm';
import UserRegCodeForm from 'components/user/UserRegCode';
import UserResetPwdForm from 'components/user/UserResetPwdForm';
import UserSignupForm from 'components/user/UserSignupForm';
import UserVerifyMailForm from 'components/user/UserVerifyMailForm';
import { isRightGranted } from 'features/admin/appSettingsSlice';
import { IBlock } from 'features/block/blockAPI';
import { BlockType, ListType } from 'features/block/blockSlice';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { StateMan, emptyDialogue } from 'features/dialogue/dialogueSlice';
import { IPhase } from 'features/phase/phaseAPI';
import { IProject } from 'features/projects/projectsAPI';
import { emptyProject } from 'features/projects/projectsSlice';
import { IUser } from 'features/user/userAPI';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { SLUGS } from 'helpers/consts';
import { pathStartsWith } from 'helpers/helpers';
import ChatSettingsForm from './ChatSettingsForm';
import CreateDialogueForm from './CreateDialogueForm';
import CreateProjectForm from './CreateProjectForm';
import DialogueForm from './DialogueForm';
import DocumentSettingsForm from './DocumentSettingsForm';
import LibrarySettingsForm from './LibrarySettingsForm';
import ListSettingsForm from './ListSettingsForm';
import MeetingSettingsForm from './MeetingSettingsForm';
import PollSettingsForm from './PollSettingsForm';
import ProjectForm from './ProjectForm';
import SharingCodesForm from './SharingCodes';
import SudokuSettingsForm from './SudokuSettingsForm';

export type BlockInfo = {
  block?: IBlock;
  phase?: IPhase;
  blockType?: BlockType;
  listType?: ListType;
};

export const FormContextInitialValue = {
  FormOpen: false,
  UserSignupForm: { setShow: (sh: boolean) => {} },
  UserLoginForm: { setShow: (sh: boolean) => {} },
  UserForgotForm: { setShow: (sh: boolean) => {} },
  UserResetPwdForm: { setShow: (sh: boolean) => {} },
  UserVerifyMailForm: { setShow: (sh: boolean) => {} },
  UserRegCodeForm: { setShow: (sh: boolean) => {} },
  UserProfileForm: {
    setShow: (sh: boolean) => {},
    setAuthor: (user?: IUser) => {},
  },

  ProjectForm: {
    setShow: (sh: boolean) => {},
    setProject: (pr: IProject) => {},
  },
  DialogueForm: {
    setShow: (sh: boolean) => {},
    setDialogue: (d: IDialogue) => {},
  },
  ListSettingsForm: {
    setShow: (sh: boolean) => {},
    setCurrentBlockInfo: (bi: BlockInfo) => {},
    setCurrentStateMan: (obj: any) => {
      return obj;
    },
    // setOnSubmit: (() => {}) as Dispatch<
    //   SetStateAction<((args?: any) => boolean) | null>
    // >,
  },
  ChatSettingsForm: {
    setShow: (sh: boolean) => {},
    setCurrentBlockInfo: (bi: BlockInfo) => {},
    setCurrentStateMan: (obj: any) => {
      return obj;
    },
    // setOnSubmit: (() => {}) as Dispatch<
    //   SetStateAction<((args?: any) => boolean) | null>
    // >,
  },
  LibrarySettingsForm: {
    setShow: (sh: boolean) => {},
    setCurrentBlockInfo: (bi: BlockInfo) => {},
    setCurrentStateMan: (obj: any) => {
      return obj;
    },
    // setOnSubmit: (() => {}) as Dispatch<
    //   SetStateAction<((args?: any) => boolean) | null>
    // >,
  },
  PollSettingsForm: {
    setShow: (sh: boolean) => {},
    setCurrentBlockInfo: (bi: BlockInfo) => {},
    setCurrentStateMan: (obj: any) => {
      return obj;
    },
    // setOnSubmit: (() => {}) as Dispatch<
    //   SetStateAction<((args?: any) => boolean) | null>
    // >,
  },
  DocumentSettingsForm: {
    setShow: (sh: boolean) => {},
    setCurrentBlockInfo: (bi: BlockInfo) => {},
    setCurrentStateMan: (obj: any) => {
      return obj;
    },
    // setOnSubmit: (() => {}) as Dispatch<
    //   SetStateAction<((args?: any) => boolean) | null>
    // >,
  },
  MeetingSettingsForm: {
    setShow: (sh: boolean) => {},
    setCurrentBlockInfo: (bi: BlockInfo) => {},
    setCurrentStateMan: (obj: any) => {
      return obj;
    },
    // setOnSubmit: (() => {}) as Dispatch<
    //   SetStateAction<((args?: any) => boolean) | null>
    // >,
  },
  SudokuSettingsForm: {
    setShow: (sh: boolean) => {},
    setCurrentBlockInfo: (bi: BlockInfo) => {},
    setCurrentStateMan: (obj: any) => {
      return obj;
    },
    // setOnSubmit: (() => {}) as Dispatch<
    //   SetStateAction<((args?: any) => boolean) | null>
    // >,
  },
  CreateDialogueForm: {
    setShow: (sh: boolean) => {},
    setDialogueTitle: (s: string) => {},
    dialogueTitle: '',
    setOnSubmit: (() => {}) as Dispatch<
      SetStateAction<((args?: any) => boolean) | null>
    >,
  },
  CreateProjectForm: {
    setShow: (sh: boolean) => {},
    setProjectTitle: (s: string) => {},
    projectTitle: '',
    setOnSubmit: (() => {}) as Dispatch<
      SetStateAction<((args?: any) => boolean) | null>
    >,
  },
  UsersForm: {
    setShow: (sh: boolean) => {},
    setCurrentEditedUser: (user: IUser) => {},
  },
  UserDataForm: {
    setShow: (sh: boolean) => {},
    setCurrentEditedUser: (user: IUser | null) => {},
    setOnSubmit: (() => {}) as Dispatch<
      SetStateAction<((args?: any) => boolean) | null>
    >,
  },
  ProjectsForm: {
    setShow: (sh: boolean) => {},
    setCurrentEditedUser: (user: IUser) => {},
  },
  AppSettingsForm: {
    setShow: (sh: boolean) => {},
  },
  WooSettingsForm: {
    setShow: (sh: boolean) => {},
  },
  SharingCodesForm: {
    setShow: (sh: boolean) => {},
  },
};

export const FormContext = createContext(FormContextInitialValue);
export const useForms = () => useContext(FormContext);
export const FormContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <FormContext.Provider value={FormContextInitialValue}>
      {children}
    </FormContext.Provider>
  );
};

function UnconnectedForms(props: UserStateProps) {
  const { userLoggedIn, userIsAdmin, userIsManager } = props;
  const [formsOpen, setFormsOpen] = useState<number>(0);
  const [UserSignupForm_show, UserSignupForm_setShow] = useState(
    pathStartsWith(SLUGS.SIGNUP) || pathStartsWith(SLUGS.GUEST)
  );
  const [UserLoginForm_show, UserLoginForm_setShow] = useState(
    pathStartsWith(SLUGS.LOGIN)
  );
  const [UserRegCodeForm_show, UserRegCodeForm_setShow] = useState(
    pathStartsWith(SLUGS.JOIN)
  );
  const [UserForgotForm_show, UserForgotForm_setShow] = useState(false);
  const [UserResetPwdForm_show, UserResetPwdForm_setShow] = useState(
    pathStartsWith(SLUGS.RESETPWD)
  );
  const [UserVerifyMailForm_show, UserVerifyMailForm_setShow] = useState(
    pathStartsWith(SLUGS.VERIFYMAIL)
  );
  const [UserProfileForm_show, UserProfileForm_setShow] = useState(false);
  const [UserProfileForm_author, UserProfileForm_setAuthor] = useState<
    IUser | undefined
  >(undefined);
  const [ProjectForm_show, ProjectForm_setShow] = useState(false);
  const [ProjectForm_project, ProjectForm_setProject] = useState<IProject>(
    emptyProject as IProject
  );
  const [DialogueForm_show, DialogueForm_setShow] = useState(false);
  const [DialogueForm_dialogue, DialogueForm_setDialogue] = useState<IDialogue>(
    emptyDialogue as IDialogue
  );
  const [UsersForm_show, UsersForm_setShow] = useState(false);
  const [UserDataForm_show, UserDataForm_setShow] = useState(false);
  const [UserDataForm_currentEditedUser, UserDataForm_setCurrentEditedUser] =
    useState<IUser | null>(null);
  const [UserDataForm_onSubmit, UserDataForm_setOnSubmit] = useState<
    ((args?: any) => boolean) | null
  >(null);
  const [ProjectsForm_show, ProjectsForm_setShow] = useState(false);
  const [AppSettingsForm_show, AppSettingsForm_setShow] = useState(false);
  const [WooSettingsForm_show, WooSettingsForm_setShow] = useState(false);
  const [SharingCodesForm_show, SharingCodesForm_setShow] = useState(false);
  const [CreateDialogueForm_show, CreateDialogueForm_setShow] = useState(false);
  const [
    CreateDialogueForm_dialogueTitle,
    CreateDialogueForm_setDialogueTitle,
  ] = useState<string>('');
  const [CreateDialogueForm_onSubmit, CreateDialogueForm_setOnSubmit] =
    useState<((args?: any) => boolean) | null>(null);
  const [CreateProjectForm_show, CreateProjectForm_setShow] = useState(false);
  const [CreateProjectForm_projectTitle, CreateProjectForm_setProjectTitle] =
    useState<string>('');
  const [CreateProjectForm_onSubmit, CreateProjectForm_setOnSubmit] = useState<
    ((args?: any) => boolean) | null
  >(null);
  const [ChatSettingsForm_show, ChatSettingsForm_setShow] = useState(false);
  // const [ChatSettingsForm_onSubmit, ChatSettingsForm_setOnSubmit] = useState<
  //   ((args?: any) => boolean) | null
  // >(null);
  const [LibrarySettingsForm_show, LibrarySettingsForm_setShow] =
    useState(false);
  // const [LibrarySettingsForm_onSubmit, LibrarySettingsForm_setOnSubmit] = useState<
  //   ((args?: any) => boolean) | null
  // >(null);
  const [PollSettingsForm_show, PollSettingsForm_setShow] = useState(false);
  // const [PollSettingsForm_onSubmit, PollSettingsForm_setOnSubmit] = useState<
  //   ((args?: any) => boolean) | null
  // >(null);
  const [DocumentSettingsForm_show, DocumentSettingsForm_setShow] =
    useState(false);
  // const [DocumentSettingsForm_onSubmit, DocumentSettingsForm_setOnSubmit] = useState<
  //   ((args?: any) => boolean) | null
  // >(null);
  const [MeetingSettingsForm_show, MeetingSettingsForm_setShow] =
    useState(false);
  // const [MeetingSettingsForm_onSubmit, MeetingSettingsForm_setOnSubmit] = useState<
  //   ((args?: any) => boolean) | null
  // >(null);
  const [ListSettingsForm_show, ListSettingsForm_setShow] = useState(false);
  // const [ListSettingsForm_onSubmit, ListSettingsForm_setOnSubmit] = useState<
  //   ((args?: any) => boolean) | null
  // >(null);
  const [SudokuSettingsForm_show, SudokuSettingsForm_setShow] = useState(false);
  // const [SudokuSettingsForm_onSubmit, SudokuSettingsForm_setOnSubmit] = useState<
  //   ((args?: any) => boolean) | null
  // >(null);
  const [Forms_currentBlockInfo, Forms_setCurrentBlockInfo] =
    useState<BlockInfo>({} as BlockInfo);
  const [Forms_currentStateMan, Forms_setCurrentStateMan] = useState<
    (obj: any) => { obj: any }
  >((obj) => {
    return obj;
  });

  const forms = useForms();

  useEffect(() => {
    function setShow(
      sh: boolean,
      formSetter: Dispatch<SetStateAction<boolean>>
    ) {
      formSetter((prev) => {
        if (prev !== sh) {
          setFormsOpen((prevO) => prevO + (sh ? 1 : prev ? -1 : 0));
        }
        return sh;
      });
    }
    // user forms
    forms.UserSignupForm.setShow = (sh: boolean) => {
      setShow(sh, UserSignupForm_setShow);
    };
    forms.UserLoginForm.setShow = (sh: boolean) => {
      setShow(sh, UserLoginForm_setShow);
    };
    forms.UserRegCodeForm.setShow = (sh: boolean) => {
      setShow(sh, UserRegCodeForm_setShow);
    };
    forms.UserForgotForm.setShow = (sh: boolean) => {
      setShow(sh, UserForgotForm_setShow);
    };
    forms.UserResetPwdForm.setShow = (sh: boolean) => {
      setShow(sh, UserResetPwdForm_setShow);
    };
    forms.UserVerifyMailForm.setShow = (sh: boolean) => {
      setShow(sh, UserVerifyMailForm_setShow);
    };
    forms.UserProfileForm.setShow = (sh: boolean) => {
      setShow(sh, UserProfileForm_setShow);
    };
    forms.UserProfileForm.setAuthor = UserProfileForm_setAuthor;
    // chat form
    forms.ChatSettingsForm.setShow = (sh: boolean) => {
      setShow(sh, ChatSettingsForm_setShow);
    };
    forms.ChatSettingsForm.setCurrentBlockInfo = Forms_setCurrentBlockInfo;
    forms.ChatSettingsForm.setCurrentStateMan = Forms_setCurrentStateMan;
    // library form
    forms.LibrarySettingsForm.setShow = (sh: boolean) => {
      setShow(sh, LibrarySettingsForm_setShow);
    };
    forms.LibrarySettingsForm.setCurrentBlockInfo = Forms_setCurrentBlockInfo;
    forms.LibrarySettingsForm.setCurrentStateMan = Forms_setCurrentStateMan;
    // poll form
    forms.PollSettingsForm.setShow = (sh: boolean) => {
      setShow(sh, PollSettingsForm_setShow);
    };
    forms.PollSettingsForm.setCurrentBlockInfo = Forms_setCurrentBlockInfo;
    forms.PollSettingsForm.setCurrentStateMan = Forms_setCurrentStateMan;
    // document form
    forms.DocumentSettingsForm.setShow = (sh: boolean) => {
      setShow(sh, DocumentSettingsForm_setShow);
    };
    forms.DocumentSettingsForm.setCurrentBlockInfo = Forms_setCurrentBlockInfo;
    forms.DocumentSettingsForm.setCurrentStateMan = Forms_setCurrentStateMan;
    // meeting form
    forms.MeetingSettingsForm.setShow = (sh: boolean) => {
      setShow(sh, MeetingSettingsForm_setShow);
    };
    forms.MeetingSettingsForm.setCurrentBlockInfo = Forms_setCurrentBlockInfo;
    forms.MeetingSettingsForm.setCurrentStateMan = Forms_setCurrentStateMan;
    // sudoku form
    forms.SudokuSettingsForm.setShow = (sh: boolean) => {
      setShow(sh, SudokuSettingsForm_setShow);
    };
    forms.SudokuSettingsForm.setCurrentBlockInfo = Forms_setCurrentBlockInfo;
    forms.SudokuSettingsForm.setCurrentStateMan = Forms_setCurrentStateMan;
    // list form
    forms.ListSettingsForm.setShow = (sh: boolean) => {
      setShow(sh, ListSettingsForm_setShow);
    };
    forms.ListSettingsForm.setCurrentBlockInfo = Forms_setCurrentBlockInfo;
    forms.ListSettingsForm.setCurrentStateMan = Forms_setCurrentStateMan;
    //project form
    forms.ProjectForm.setShow = (sh: boolean) => {
      setShow(sh, ProjectForm_setShow);
    };
    forms.ProjectForm.setProject = ProjectForm_setProject;
    // dialogue form
    forms.DialogueForm.setShow = (sh: boolean) => {
      setShow(sh, DialogueForm_setShow);
    };
    forms.DialogueForm.setDialogue = DialogueForm_setDialogue;
    // create dialogue form
    forms.CreateDialogueForm.setShow = (sh: boolean) => {
      setShow(sh, CreateDialogueForm_setShow);
    };
    forms.CreateDialogueForm.setDialogueTitle =
      CreateDialogueForm_setDialogueTitle;
    forms.CreateDialogueForm.setOnSubmit = CreateDialogueForm_setOnSubmit;
    // create project form
    forms.CreateProjectForm.setShow = (sh: boolean) => {
      setShow(sh, CreateProjectForm_setShow);
    };
    forms.CreateProjectForm.setProjectTitle = CreateProjectForm_setProjectTitle;
    forms.CreateProjectForm.setOnSubmit = CreateProjectForm_setOnSubmit;
    // users admin forms
    forms.UsersForm.setShow = (sh: boolean) => {
      setShow(sh, UsersForm_setShow);
    };
    forms.UserDataForm.setShow = (sh: boolean) => {
      setShow(sh, UserDataForm_setShow);
    };
    forms.UserDataForm.setCurrentEditedUser = UserDataForm_setCurrentEditedUser;
    forms.UserDataForm.setOnSubmit = UserDataForm_setOnSubmit;
    // projects admin form
    forms.ProjectsForm.setShow = (sh: boolean) => {
      setShow(sh, ProjectsForm_setShow);
    };
    // App settings admin form
    forms.AppSettingsForm.setShow = (sh: boolean) => {
      setShow(sh, AppSettingsForm_setShow);
    };
    // Woo settings admin form
    forms.WooSettingsForm.setShow = (sh: boolean) => {
      setShow(sh, WooSettingsForm_setShow);
    };
    // Sharing codes form
    forms.SharingCodesForm.setShow = (sh: boolean) => {
      setShow(sh, SharingCodesForm_setShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  useEffect(() => {
    forms.CreateDialogueForm.dialogueTitle = CreateDialogueForm_dialogueTitle;
    forms.CreateProjectForm.projectTitle = CreateProjectForm_projectTitle;
  }, [
    CreateDialogueForm_dialogueTitle,
    forms.CreateDialogueForm,
    CreateProjectForm_projectTitle,
    forms.CreateProjectForm,
  ]);

  useEffect(() => {
    forms.FormOpen = formsOpen > 0;
  }, [formsOpen]);

  return (
    <>
      {!userLoggedIn ? ( // forms for anonymous users
        <>
          <UserLoginForm
            show={UserLoginForm_show}
            setShow={forms.UserLoginForm.setShow}
          />
          <UserSignupForm
            show={UserSignupForm_show}
            setShow={forms.UserSignupForm.setShow}
          />
          <UserForgotForm
            show={UserForgotForm_show}
            setShow={forms.UserForgotForm.setShow}
          />
          <UserResetPwdForm
            show={UserResetPwdForm_show}
            setShow={forms.UserResetPwdForm.setShow}
          />
          <UserVerifyMailForm
            show={UserVerifyMailForm_show}
            setShow={forms.UserVerifyMailForm.setShow}
          />
        </>
      ) : (
        // forms for logged in users (who could be moderators)
        <>
          <UserSignupForm
            show={UserSignupForm_show}
            setShow={forms.UserSignupForm.setShow}
          />
          <UserProfileForm
            show={UserProfileForm_show}
            setShow={forms.UserProfileForm.setShow}
            author={UserProfileForm_author}
          />
          <UserResetPwdForm
            show={UserResetPwdForm_show}
            setShow={forms.UserResetPwdForm.setShow}
          />
          <UserVerifyMailForm
            show={UserVerifyMailForm_show}
            setShow={forms.UserVerifyMailForm.setShow}
          />
          <UserRegCodeForm
            show={UserRegCodeForm_show}
            setShow={forms.UserRegCodeForm.setShow}
          />
          <ProjectForm
            show={ProjectForm_show}
            setShow={forms.ProjectForm.setShow}
            project={ProjectForm_project}
          />
          <DialogueForm
            show={DialogueForm_show}
            setShow={forms.DialogueForm.setShow}
          />
          <ChatSettingsForm
            show={ChatSettingsForm_show}
            setShow={forms.ChatSettingsForm.setShow}
            stateMan={Forms_currentStateMan as StateMan}
            blockInfo={Forms_currentBlockInfo as BlockInfo}
            // onSubmit={ChatSettingsForm_onSubmit}
          />
          <LibrarySettingsForm
            show={LibrarySettingsForm_show}
            setShow={forms.LibrarySettingsForm.setShow}
            stateMan={Forms_currentStateMan as StateMan}
            blockInfo={Forms_currentBlockInfo as BlockInfo}
            // onSubmit={LibrarySettingsForm_onSubmit}
          />
          <PollSettingsForm
            show={PollSettingsForm_show}
            setShow={forms.PollSettingsForm.setShow}
            stateMan={Forms_currentStateMan as StateMan}
            blockInfo={Forms_currentBlockInfo as BlockInfo}
            // onSubmit={PollSettingsForm_onSubmit}
          />
          <DocumentSettingsForm
            show={DocumentSettingsForm_show}
            setShow={DocumentSettingsForm_setShow}
            stateMan={Forms_currentStateMan as StateMan}
            blockInfo={Forms_currentBlockInfo as BlockInfo}
            // onSubmit={DocumentSettingsForm_onSubmit}
          />
          <MeetingSettingsForm
            show={MeetingSettingsForm_show}
            setShow={forms.MeetingSettingsForm.setShow}
            stateMan={Forms_currentStateMan as StateMan}
            blockInfo={Forms_currentBlockInfo as BlockInfo}
            // onSubmit={MeetingSettingsForm_onSubmit}
          />
          <ListSettingsForm
            show={ListSettingsForm_show}
            setShow={forms.ListSettingsForm.setShow}
            blockInfo={Forms_currentBlockInfo as BlockInfo}
            stateMan={Forms_currentStateMan as StateMan}
            // onSubmit={ListSettingsForm_onSubmit}
          />
          <SudokuSettingsForm
            show={SudokuSettingsForm_show}
            setShow={forms.SudokuSettingsForm.setShow}
            stateMan={Forms_currentStateMan as StateMan}
            blockInfo={Forms_currentBlockInfo as BlockInfo}
            // onSubmit={SudokuSettingsForm_onSubmit}
          />
          <SharingCodesForm
            show={SharingCodesForm_show}
            setShow={forms.SharingCodesForm.setShow}
          />
          {userIsManager || isRightGranted('userCreateProject') ? (
            <>
              <CreateProjectForm
                show={CreateProjectForm_show}
                setShow={forms.CreateProjectForm.setShow}
                setProjectTitle={CreateProjectForm_setProjectTitle}
                onSubmit={CreateProjectForm_onSubmit}
              />
              <CreateDialogueForm
                show={CreateDialogueForm_show}
                setShow={forms.CreateDialogueForm.setShow}
                setDialogueTitle={CreateDialogueForm_setDialogueTitle}
                onSubmit={CreateDialogueForm_onSubmit}
              />
            </>
          ) : null}
          {userIsManager ? ( // forms for managers
            <></>
          ) : null}
          {userIsAdmin ? ( // forms for admins
            <>
              <UsersForm
                show={UsersForm_show}
                setShow={forms.UsersForm.setShow}
              />
              <UserDataForm
                show={UserDataForm_show}
                setShow={forms.UserDataForm.setShow}
                currentEditedUser={UserDataForm_currentEditedUser as IUser}
                onSubmit={UserDataForm_onSubmit}
              />
              <ProjectsForm
                show={ProjectsForm_show}
                setShow={forms.ProjectsForm.setShow}
              />
              <AppSettingsForm
                show={AppSettingsForm_show}
                setShow={forms.AppSettingsForm.setShow}
              />
              <WooSettingsForm
                show={WooSettingsForm_show}
                setShow={forms.WooSettingsForm.setShow}
              />
            </>
          ) : null}
        </>
      )}
    </>
  );
}

const Forms = connect(mapUserStateToProps)(UnconnectedForms);
export default Forms;
