import {
  SortableItemType,
  SortableListType,
} from 'components/sortables/Sortable';
import { AnyIdObject, AnyObject, AnyOrderObject } from './objects';

// const letters = '0123456789'; // base 10
const lettersunsorted =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz'; // base 26+
const letters = lettersunsorted.split('').sort().join('');

function half(xx: string) {
  let x = xx.split('');
  let c = '';
  let carry = 0;
  for (let i = 0; i < x.length || carry !== 0; i++) {
    const digit = i < x.length ? letters.indexOf(x[i]) : 0;
    const value = digit / 2 + carry * letters.length;
    c += letters[Math.floor(value)];
    carry = value - Math.floor(value);
  }
  return c;
}

// we can safely assume that we do not overflow the most important bit
function add(aa: string, bb: string) {
  let a = aa.split('');
  let b = bb.split('');
  let c = '';
  let carry = 0;
  for (let i = Math.max(a.length, b.length) - 1; i >= 0; i--) {
    const aa = i < a.length ? letters.indexOf(a[i]) : 0;
    const bb = i < b.length ? letters.indexOf(b[i]) : 0;
    const value = aa + bb + carry;
    c = letters[value % letters.length] + c;
    carry = Math.floor(value / letters.length);
  }
  return c;
}

function average(a: string, b: string) {
  return add(half(a), half(b));
}

export function sortByOrder(items: AnyOrderObject[]): AnyOrderObject[] {
  return sortBy(items, 'order') as AnyOrderObject[];
}

export function sortByOrderAndExcludeIds(
  items: AnyOrderObject[],
  exclude: number[],
): AnyOrderObject[] {
  return sortBy(excludeKeys(items, 'id', exclude), 'order') as AnyOrderObject[];
}

export function sortByTime(
  items: AnyObject[],
  key: string = 'time',
  direction: 'ASC' | 'DSC' = 'ASC',
): AnyObject[] {
  const timeItems = items.map((item: AnyObject) => ({
    ...item,
    [key]: new Date(item[key]),
  }));
  return sortBy(timeItems, key, direction, (o) => {
    return new Date(o as Date);
  });
}

export function sortBy(
  items: AnyObject[],
  key: string,
  direction: 'ASC' | 'DSC' = 'ASC',
  transform?: (v: any) => any,
  key2?: string,
  direction2?: 'ASC' | 'DSC',
  transform2?: (v: any) => any,
): AnyObject[] {
  if (items == null) return [];

  const ordered = [...items];
  try {
    ordered.sort((a, b) => {
      const aval = transform ? transform(a[key]) : a[key];
      const bval = transform ? transform(b[key]) : b[key];
      let res = // TODO check if aval xor bval === null
        (aval === bval
          ? 0
          : aval === null && bval !== null
            ? direction === 'DSC'
              ? -1
              : 1
            : aval !== null && bval === null
              ? direction === 'DSC'
                ? 1
                : -1
              : aval < bval
                ? -1
                : aval > bval
                  ? 1
                  : 0) * (direction === 'DSC' ? -1 : 1);
      if (res === 0 && key2) {
        const a2val = transform2 ? transform2(a[key2]) : a[key2];
        const b2val = transform2 ? transform2(b[key2]) : b[key2];
        res =
          (a2val < b2val ? -1 : a2val > b2val ? 1 : 0) *
          (direction2 === 'DSC' ? -1 : 1);
      }
      return res;
    });
    return ordered;
  } catch {
    throw new Error(
      `Error: failed to order items by '${key}' that have no such attribute.`,
    );
  }
}

export function excludeKeys(
  items: AnyObject[],
  key: string,
  exclude: any[], // array of values for key to exclude, e.g., array of id's when key === 'id'
) {
  return items.filter((item) => !exclude.includes(item[key]));
}

// get the new order attribute
// for moving the item from fromIndex to toIndex.
export function getOrderUsingIndex(
  items: AnyOrderObject[],
  from: number, // -1 when moving between lists
  to: number, // -1 when moving to end of list
  before?: boolean,
): string {
  const len = items.length;
  if (len === 0) return letters[1];
  if (to > len || from >= len) throw new Error(`Invalid index for sorting.`);
  // prettier-ignore
  let pre =
    to === 0
      ? before
        ? -1
        : to
      : from < 0
        ? to < 0
          ? len > 0
            ? len - 1
            : -1
          : before
            ? to - 1
            : to
        : from < to
          ? before
            ? to - 1
            : to
          : before
            ? to - 1
            : to;
  if (from === pre + 1) pre = from;
  // prettier-ignore
  const post =
    from >= 0 && from === pre
    ? pre
    : pre < 0
      ? 0
      : pre < len - 1
        ? pre + 1
        : -1;
  const preOrder = pre < 0 ? letters[0] : items[pre].order;
  const postOrder = post < 0 ? letters[letters.length - 1] : items[post].order;
  const newOrder = pre !== post ? average(preOrder, postOrder) : preOrder;
  return newOrder;
}

// get the new order attribute
// for moving the item with fromId to position of toId.
export function getOrderUsingId(
  items: AnyOrderObject[],
  fromId: number,
  toId: number,
  before?: boolean,
): string {
  const fromIndex = items.findIndex((item) => item.id === fromId);
  const toIndex = items.findIndex((item) => item.id === toId);
  return getOrderUsingIndex(items, fromIndex, toIndex, before);
}

export function getOrderForLast(
  items: AnyOrderObject[],
  fromIndex: number = -1, // -1 for new list
): string {
  if (!items.length) return letters[1];
  const sorted = sortByOrder(items);
  // return getOrderUsingIndex(sorted, fromIndex, sorted.length - 1);
  return getOrderUsingIndex(sorted, fromIndex, -1);
}

export function getOrderByIndex(
  nextIndex: number = -1, // -1 for new list
): string {
  if (nextIndex < 1) return letters[1];
  return letters[1 + nextIndex];
}

export function getNextOrder(items: AnyOrderObject[], alpha: boolean): string {
  const start = alpha ? letters.indexOf('B') : 1;
  if (!items.length) return letters[start];
  const sorted = sortByOrder(items);
  if (sorted.length >= letters.length - start - 1) {
    return getOrderUsingIndex(sorted, 0, sorted.length - 1);
  }
  return letters[sorted.length + start];
}

export function getNextOrderAfterLast(
  lastOrder: string | null,
  alpha: boolean,
): string {
  if (!lastOrder || lastOrder === '') return alpha ? 'B' : '1';
  const items = [{ order: lastOrder, id: 0 }];
  return getNextOrder(items, alpha);
}

/*
 * For Sortable contexts
 */
export type UniqueId = string;
export type UniqueItem = {
  id: UniqueId;
  object: any;
};
export function toUniqueId(
  id: number | number[],
  type: SortableListType | SortableItemType,
): UniqueId {
  return Array.isArray(id)
    ? (type + '_').concat(id.join('_'))
    : type + '_' + id;
}
export function toUniqueIds(
  arr: AnyIdObject[],
  type: SortableListType | SortableItemType,
): UniqueId[] {
  return arr.map((item) => toUniqueId(item.id, type));
}
export function fromUniqueId(id: UniqueId): {
  id: number;
  relatedIds?: number[];
  type: SortableListType | SortableItemType;
} {
  const s: string[] = id.split('_');
  if (s.length < 2) return { id: 0, type: SortableItemType.SortableItem };
  return {
    id: parseInt(s[1]),
    type: s[0] as SortableListType | SortableItemType,
    relatedIds: s.length > 2 ? s.slice(2).map((i) => parseInt(i)) : undefined,
  };
}
export function fromUniqueIds(arr: UniqueId[]): {
  type: SortableListType | SortableItemType;
  ids: number[];
} {
  let type: SortableListType | SortableItemType | undefined = undefined;
  return {
    type: type! as SortableListType | SortableItemType,
    ids: arr.map((id) => {
      if (!type) type = fromUniqueId(id).type;
      return fromUniqueId(id).id;
    }),
  };
}
export function wrapForSortable(
  object: AnyIdObject[],
  type: string,
): UniqueItem[] {
  return object.map((o) => {
    return { id: type + ':' + o.id, object: o };
  });
}
export function unwrapSortable(item: UniqueItem[]): AnyIdObject[] {
  return item.map((i) => i.object);
}
export function findSortableObject(
  items: UniqueItem[],
  id: UniqueId,
): AnyIdObject | undefined {
  return items.find((item) => item.id === id)?.object;
}
export function findSortableIndex(items: UniqueItem[], id: UniqueId): number {
  return items.findIndex((item) => item.id === id);
}

export function findAncestor(
  element: HTMLElement,
  className: string,
): HTMLElement {
  let ancestor: HTMLElement = element;
  while (ancestor !== null) {
    if (ancestor.classList.contains(className)) {
      return ancestor;
    }
    if (ancestor.parentElement) ancestor = ancestor.parentElement;
    else break;
  }
  return ancestor;
}

export function findSibling(
  element: HTMLElement,
  className: string,
): HTMLElement | null {
  const parent = element.parentElement;
  if (parent) return findChild(parent, className);
  return null;
}

export function findChild(
  element: HTMLElement,
  className: string,
): HTMLElement | null {
  const children = element.children;
  for (let i = 0; i < children.length; i++) {
    const child = children[i] as HTMLElement;
    if (child.classList.contains(className)) {
      return child;
    }
  }
  return null;
}
