import classNames from 'classnames';
import { ToolHint, ToolHintProps } from 'components/messages/ToolHint';
import { MouseEventHandler, ReactNode, useRef } from 'react';

import './Icon.scss';

export enum IconSymbol {
  alert = 'alert',
  audio = 'audio',
  audio_off = 'audio-off',
  bin = 'bin',
  canvas = 'canvas',
  check = 'check',
  check_not = 'check-not',
  close = 'close',
  cloud_saved = 'cloud-saved',
  cloud_saving = 'cloud-saving',
  copy = 'copy',
  cycle = 'cycle',
  down = 'down',
  dot = 'dot',
  exit = 'exit',
  folder = 'folder',
  folder_open = 'folder-open',
  gallery = 'gallery',
  gallery_close = 'gallery-close',
  info = 'info',
  key = 'key',
  left = 'left',
  link = 'link',
  lock = 'lock',
  unlock = 'unlock',
  longlist = 'longlist',
  minus = 'minus',
  moderator = 'shield_check',
  new_window = 'new-window',
  not_moderator = 'shield',
  pencil = 'pencil',
  picture = 'picture',
  pin = 'pin',
  pin2 = 'pin2',
  unpin = 'unpin',
  plus = 'plus',
  record = 'record',
  recording = 'recording',
  reply = 'reply',
  right = 'right',
  scroll = 'scroll',
  share = 'share',
  star = 'star',
  star_bg = 'star_bg',
  star_bg_grey = 'star_bg_grey',
  table = 'table',
  thumb = 'thumb',
  up = 'up',
  user = 'user',
  user_unsubscribed = 'user-unsubscribed',
  video = 'video',
  video_off = 'video-off',
  // block types
  chat = 'chat',
  list = 'list',
  library = 'library',
  document = 'document',
  poll = 'poll',
  meeting = 'meeting',
  sudoku = 'sudoku',
  // list types
  procon = 'procon',
  swot = 'swot',
  kanban = 'kanban',
  singlelist = 'list',
  multilist = 'multilist',
  // mime types
  mime_img = 'mime-img',
  mime_pdf = 'mime-pdf',
  mime_docx = 'mime-docx',
  mime_xlsx = 'mime-xlsx',
  mime_pptx = 'mime-pptx',
  mime_unknown = 'mime-unknown',
  // list icons
  todo = 'new_item',
  doing = 'cycle',
  done = 'shield_check',
  smiling = 'smiling',
  sad = 'sad',
  saved = 'saved',
  strength = 'shield_plus',
  weakness = 'shield_minus',
  opportunity = 'trend-up',
  threat = 'trend-down',
}
export enum IconVariant {
  normal = '',
  dark = 'dark',
  medium = 'medium',
  light = 'light',
  accent = 'accent',
  filled = 'filled',
  light_filled = 'light-filled',
  dark_filled = 'dark-filled',
}

type IconProps = {
  symbol: IconSymbol | null;
  variant?: IconVariant;
  hoverSymbol?: IconSymbol;
  hoverVariant?: IconVariant;
  className?: string;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  size?: number; // size in pixels, sets width and height as element style
  style?: any; // element style, overrides width and height set through the size prop
  hintProps?: ToolHintProps;
  blurBg?: boolean;
  inline?: boolean;
  children?: ReactNode;
};

export function getIconSymbol(s: string): IconSymbol {
  try {
    const k: keyof typeof IconSymbol =
      s.toLowerCase() as keyof typeof IconSymbol;
    return IconSymbol[k];
  } catch (err) {
    throw new Error('No icon defined for type: ' + s);
  }
}

export function getIconUrl(symbol: IconSymbol, variant?: IconVariant): string {
  const v = variant ? '-' + variant.toString() : '';
  return `/icons/${symbol}${v}.svg`;
}

export default function Icon(props: IconProps) {
  const {
    symbol,
    hoverSymbol,
    variant,
    hoverVariant,
    className,
    onClick,
    disabled = false,
    size,
    style,
    hintProps,
    blurBg = false,
    inline = false,
    children,
  } = props;
  const triggerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);

  function handleHover(hover: boolean) {
    if (imageRef.current && symbol && (hoverSymbol || hoverVariant)) {
      const sym = hover ? (hoverSymbol ?? symbol) : symbol;
      const vnt = hover ? (hoverVariant ?? variant) : variant;
      imageRef.current.style.backgroundImage = `url(${getIconUrl(sym, vnt)})`;
    }
  }

  return (
    <>
      {symbol === null ? null : (
        <>
          <div
            className={classNames(className, 'pd_icon', {
              button: !!onClick && !disabled,
              pd_icon_blur: blurBg,
              inline: inline,
            })}
            style={{
              // width: size ? size + 'px' : undefined,
              height: size ? size + 'px' : undefined,
              ...style,
            }}
            onClick={!disabled ? onClick : undefined}
            onMouseOver={
              disabled
                ? undefined
                : hoverSymbol || hoverVariant
                  ? () => handleHover(true)
                  : undefined
            }
            onMouseOut={
              disabled
                ? undefined
                : hoverSymbol || hoverVariant
                  ? () => handleHover(false)
                  : undefined
            }
            ref={triggerRef}
          >
            {/* {blurBg ? <div className='pd_icon_bg' />
            : null } */}
            <div
              className="pd_icon_img"
              style={{
                backgroundImage: `url(${getIconUrl(symbol, variant)})`,
                backgroundPosition: 'center',
                // backgroundSize: '100%',
                // width: size ? size + 'px' : undefined,
                height: size ? size + 'px' : undefined,
                ...style,
              }}
              ref={imageRef}
            />
            {children}
            {hintProps ? (
              <ToolHint {...hintProps} toolRef={triggerRef} />
            ) : null}
          </div>
        </>
      )}
    </>
  );
}
