import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import {
  arraySomeById,
  deepSearchKey,
  deepSearchKeyType,
  findIndexById,
} from 'app/AsyncAPI/helpers';
import { IDialogueState, StateMan } from 'features/dialogue/dialogueSlice';
import { postFile } from 'features/files/fileAPI';
import { FolderLocation, getFolderName } from 'features/files/fileSlice';
import { IUser } from 'features/user/userAPI';
import { findObjectInState, idOf, updateObject } from 'helpers/objects';
import {
  IMessage,
  IMessageCreateReply,
  deleteMessage,
  patchMessage,
  postMessage,
} from './messageAPI';

export const postMessageAsync = createAsyncThunk(
  'message/postMessage',
  async (
    {
      data,
      file,
      author,
      stateMan,
    }: {
      data: IMessageCreateReply;
      file?: File & { data?: any };
      author: IUser;
      stateMan?: StateMan;
    },
    thunkAPI,
  ) => {
    try {
      let res;
      if (file) {
        const fd = await postFile(file, {
          description: 'User upload',
          folder: getFolderName(FolderLocation.Dialogue, 'UserUploads'),
          order: '',
          size: file.size,
          mimeType: file.type,
        });
        data.filesAdd = [{ id: fd.data._id }];
        file.data = [
          {
            description: 'User upload',
            id: fd.data._id,
            fileName: file.name,
            mimeType: file.type,
            order: '',
            size: file.size,
            uri: fd.data.message,
          },
        ];
        res = await postMessage(data);
        // res.data.files = [{ id: fd.data._id, uri: fd.data.message }];
      } else res = await postMessage(data);
      return { response: res, stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const patchMessageAsync = createAsyncThunk(
  'message/updateMessage',
  async (
    {
      data,
      stateMan,
    }: {
      data: IMessage;
      stateMan: StateMan;
    },
    thunkAPI,
  ) => {
    try {
      return { response: await patchMessage(data), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const deleteMessageAsync = createAsyncThunk(
  'message/deleteMessage',
  async (
    {
      message,
      stateMan,
    }: {
      message: IMessage;
      stateMan: StateMan;
    },
    thunkAPI,
  ) => {
    try {
      return { response: await deleteMessage(message.id as number), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  },
);

export const asyncApiMessagesNew = createAsyncThunk(
  '/messages/asyncApiMessagesNew',
  async ({ data, stateMan }: { data: any; stateMan: StateMan }) => {
    return { data, stateMan };
  },
);
export const asyncApiMessagesUpdated = createAsyncThunk(
  '/messages/asyncApiMessagesUpdated',
  async ({ data, stateMan }: { data: any; stateMan: StateMan }) => {
    return { data, stateMan };
  },
);
export const asyncApiMessagesRemoved = createAsyncThunk(
  '/messages/asyncApiMessagesRemoved',
  async ({ data, stateMan }: { data: any; stateMan: StateMan }) => {
    return { data, stateMan };
  },
);

export function addMessageCases(
  builder: ActionReducerMapBuilder<IDialogueState>,
) {
  builder
    .addCase(patchMessageAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(patchMessageAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state); // message
      const msg = stateObj.hasOwnProperty('content')
        ? stateObj
        : findObjectInState('listmessage', stateObj, action.meta.arg.data.id!);
      if (msg) {
        Object.assign(msg, action.meta.arg.data);
      } else
        console.log(
          "Oops, your update got lost, but no worries - we've saved already.",
        );
    })
    .addCase(patchMessageAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(postMessageAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(postMessageAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      if (action.meta.arg.stateMan === undefined) return;
      const stateObj = action.meta.arg.stateMan(state);
      const msgs = stateObj.hasOwnProperty('messages')
        ? stateObj.messages
        : stateObj.hasOwnProperty('links')
          ? stateObj.links
          : findObjectInState(
              'listmessage',
              stateObj,
              idOf(action.meta.arg.data.replyTo)!,
            )?.replies;
      if (msgs) {
        const id = action.payload.response.data._id;
        const data = {
          ...action.meta.arg.data,
          author: action.meta.arg.author,
          id: id,
          files: action.meta.arg.file?.data,
          // files: action.payload.response.data.files,
        };
        msgs.push(data);
      } else
        console.log(
          "Oops, your message got lost, but no worries - we've saved already.",
        );
    })
    .addCase(postMessageAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })

    .addCase(deleteMessageAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(deleteMessageAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const msg = action.meta.arg.message;
      const id = msg.id;
      let msgs = stateObj.hasOwnProperty('messages')
        ? stateObj.messages
        : stateObj.links;
      if (!msgs && msg.replyTo)
        // messages not found, we could be deleting a reply to a listItem
        msgs = findObjectInState(
          'listmessage',
          stateObj,
          msg.replyTo.id!,
        )?.replies;
      if (msgs) {
        msgs.splice(
          msgs.findIndex((m: any) => m.id === id),
          1,
        );
        return;
      }
      console.log(
        'Oops, your message got lost, but no worries - it has been deleted already.',
      );
    })
    .addCase(deleteMessageAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })

    .addCase(asyncApiMessagesNew.fulfilled, (state, action) => {})
    .addCase(asyncApiMessagesUpdated.fulfilled, (state, action) => {
      const payload = action.meta.arg.data;

      const stateObj = action.meta.arg.stateMan(state);
      const messages = stateObj.messages;

      const rootUpdated = payload.updated;

      if (deepSearchKey(rootUpdated, 'messages')) {
        const payloadMessages = rootUpdated.messages;

        const isLikes = deepSearchKeyType(
          payloadMessages,
          'likes',
          'array',
          true,
        );

        if (!isLikes && deepSearchKey(payloadMessages, 'new')) {
          for (const newMessage of payloadMessages.new) {
            if (!arraySomeById(messages, newMessage.id)) {
              messages.push(newMessage);
            }
          }
        }

        if (deepSearchKey(payloadMessages, 'updated')) {
          for (const updatedMessage of payloadMessages.updated) {
            const messageIndex = findIndexById(messages, updatedMessage.id);

            if (isLikes) {
              const updatedLikes = updatedMessage.updated.likes;

              if (deepSearchKey(updatedLikes, 'new')) {
                for (const like of updatedLikes.new) {
                  if (!arraySomeById(messages[messageIndex].likes, like.id)) {
                    messages[messageIndex].likes.push(like);
                  }
                }
              }
              if (deepSearchKey(updatedLikes, 'removed')) {
                for (const like of updatedLikes.removed) {
                  const likeIndex = findIndexById(
                    messages[messageIndex].likes,
                    like,
                  );

                  if (likeIndex !== -1) {
                    messages[messageIndex].likes.splice(likeIndex, 1);
                  }
                }
              }
            } else {
              messages[messageIndex] = updateObject(
                messages[messageIndex],
                updatedMessage.updated,
              );
            }
          }
        }

        if (!isLikes && deepSearchKey(payloadMessages, 'removed')) {
          for (const deletedMessage of payloadMessages.removed) {
            const messageIndex = findIndexById(messages, deletedMessage);

            if (messageIndex !== -1) {
              messages.splice(messageIndex, 1);
            }
          }
        }
      }
    })
    .addCase(asyncApiMessagesRemoved.fulfilled, (state, action) => {});
}
